<nav class="side-bar scroll-wrapper">
  <ul class="wrapper scroll">
    <li *ngFor="let applicativeArea of applicativeAreas" class="parent"
      [ngClass]="{ 'has-childs': hasChildren(applicativeArea.code) || applicativeArea.configurations?.length > 0, 'active' : isActiveLink(applicativeArea.code) }">

      <a [ngClass]="{'current-link': isActiveLink(applicativeArea.code)}"
        *ngIf="hasChildren(applicativeArea.code) || applicativeArea.configurations?.length > 0; else elseBlock" (click)="redirectToUrl($event, applicativeArea.code)">
        {{applicativeArea.label}} <mat-icon *ngIf="hasChildren(applicativeArea.code) || applicativeArea.configurations?.length > 0">expand_more</mat-icon>
      </a>

      <ng-template #elseBlock>
        <a [routerLink]="['/applicativeAreaParameters', applicativeArea.code]" 
           [ngClass]="{'current-link': isActiveLink(applicativeArea.code)}">
          {{applicativeArea.label}}
        </a>
      </ng-template>
      <ul class="child-wrapper" *ngIf="hasChildren(applicativeArea.code); else elseBlock1">
        <li class="child" *ngFor="let applicativeAreaChild of getApplicativeAreaChildren(applicativeArea.code)"
          [routerLinkActive]="['current-link-wrapper']"
          [ngClass]="{ 'has-config' : ( applicativeAreaChild.configurations?.length > 0 ) }">
          <a [routerLink]="['/applicativeAreaParameters', applicativeAreaChild.code]"
            (click)="clickSubLink(applicativeAreaChild.code)"
            [ngClass]="{'current-link current-sub-link' : ( applicativeAreaChild.code === activeSubItem )}">
            <span class="link-wrapper" *ngIf="applicativeAreaChild.configurations?.length > 0; else elseBlock">
              {{applicativeAreaChild.label}}
              <mat-icon>expand_more</mat-icon>

              <ul class="config-wrapper">
                <li *ngFor="let configuration of applicativeAreaChild.configurations">
                  <a (click)="redirectToCustomRoute($event, configuration.code)"
                  [ngClass]="{'current-active-link' : ( configuration.code === activeConfigItem )}">
                    {{ configuration.label }} 
                  </a>
                </li>
              </ul>
            </span>

            <ng-template #elseBlock>
              {{applicativeAreaChild.label}}
            </ng-template>
          </a>
        </li>
      </ul>
      <ng-template #elseBlock1>
        <ul class="child-wrapper" *ngIf="applicativeArea.configurations?.length > 0">
          <li class="child" *ngFor="let configuration of applicativeArea.configurations"
            [routerLinkActive]="['current-link-wrapper']">
            <a (click)="redirectToCustomRoute($event, configuration.code)" 
            [ngClass]="{'current-link current-sub-link' : ( configuration.code === activeConfigItem )}">
              {{ configuration.label }}
            </a>
          </li>
        </ul>
      </ng-template>
    </li>
  </ul>
</nav>
