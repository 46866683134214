import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CustomWebSearchModel, CustomWebSearchService } from 'common-services';
import { Subscription } from 'rxjs';
import { ConfirmDialogComponent } from 'src/app/generic-components/confirm-dialog/confirm-dialog.component';
import { EditableTableComponent } from 'src/app/generic-components/editable-table/editable-table.component';
import { FormDialogComponent } from 'src/app/generic-components/form-dialog/form-dialog.component';
import { Dialogs } from 'src/app/shared/enums/dialogs-enum';
import { TranslatableDomainModelName } from 'src/app/shared/enums/translatable-domain-model-name.enum';
import { UtilHelper } from 'src/app/shared/helpers/util.helper';
import { DialogsManager } from 'src/app/shared/managers/dialog.manager';
import { FieldConfigModel } from 'src/app/shared/models/field.model';

@Component({
  selector: 'app-custom-web-search',
  templateUrl: './custom-web-search.component.html',
  styleUrls: ['./custom-web-search.component.scss']
})
export class CustomWebSearchComponent implements OnInit, OnDestroy {
  @ViewChild('editableTable', { static: true }) editableTable: EditableTableComponent<CustomWebSearchModel>;

  public webSearchSocialNetworks: CustomWebSearchModel[];
  public displayedColumns: string[];
  public translatableDomainModelName: string;
  public title: string;

  private translations: Record<string, string>;
  private hiddenFields: string[];
  private fieldsConfig: FieldConfigModel[] = [];
  private fieldsModel: string[];
  private readonly subscription: Subscription;

  constructor(
    private readonly customWebSearchService: CustomWebSearchService,
    private readonly dialogManager: DialogsManager,
    private readonly translateService: TranslateService,
    private readonly utilHelper: UtilHelper
  ) {
    this.subscription = new Subscription();
    this.displayedColumns = ['label', 'key', 'order'];
    this.hiddenFields = ['id', 'translationId'];
    this.fieldsModel = ['id', 'label', 'key', 'order', 'translationId'];
    this.translatableDomainModelName = TranslatableDomainModelName.customWebSearch;
    this.subscription = new Subscription();
  }

  ngOnInit(): void {
    const translateSubscription = this.translateService.get(
      [
        'new',
        'update',
        'deleteConfirmationTitle',
        'deleteConfirmationMessage',
        'delete',
        'label',
        'code',
        'order',
        'key',
        'webSocialNetworks'
      ]
    ).subscribe(translation => {
      this.translations = translation;
      this.title = translation.webSocialNetworks;
    });

    this.subscription.add(translateSubscription);
    this.getWebSearchSocialNetworks();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  // Crée un moteur de recherche personnalisé
  public onCreate(): void {
    this.createFormFields();

    // On initialise les valeurs des champs
    this.fieldsConfig.forEach(field => {
      field.value = (field.name === 'order') ?
        this.utilHelper.incrementOrder(this.webSearchSocialNetworks.map(webSearch => webSearch.order)) :
        (this.hiddenFields.includes(field.label) ? 0 : '');
    });

    const data = {
      title: this.translations.new,
      fieldsConfig: this.fieldsConfig
    };

    const formDialogComponent = this.dialogManager.openDialog(FormDialogComponent, Dialogs.formDialog, data).componentInstance;
    formDialogComponent.save.subscribe((customWebSearch: CustomWebSearchModel) => {
      customWebSearch.id = 0;
      this.customWebSearchService.createCustomWebSearches(customWebSearch).then(() => {
        this.getWebSearchSocialNetworks();
        formDialogComponent.dialogRef.close();
        this.utilHelper.showNotification('addSuccess');
      }).catch(() => {
        this.utilHelper.showNotification('error', false);
      });
    });
  }

  // Met à jour un moteur de recherche personnalisé
  public onEdit(customWebSearchModel: CustomWebSearchModel): void {
    this.createFormFields(false);
    // On récupère les valeurs des champs
    if (customWebSearchModel) {
      this.fieldsConfig.forEach(field => {
        Object.entries(customWebSearchModel).forEach(([key, value]) => {
          if (field.name === key) {
            field.value = value;
          }
        });
      });
    }

    const data = {
      title: `${this.translations.update} (${customWebSearchModel.label})`,
      fieldsConfig: this.fieldsConfig
    };

    const formDialogComponent = this.dialogManager.openDialog(FormDialogComponent, Dialogs.formDialog, data).componentInstance;
    formDialogComponent.save.subscribe((customWebSearch: CustomWebSearchModel) => {
      this.customWebSearchService.updateCustomWebSearches(customWebSearch).then(() => {
        this.getWebSearchSocialNetworks();
        formDialogComponent.dialogRef.close();
        this.utilHelper.showNotification('updateSuccess');
      }).catch(() => {
        this.utilHelper.showNotification('error', false);
      });
    });
  }

  // Supprime un moteur de recherche personnalisé
  public onDelete(customWebSearch: CustomWebSearchModel): void {
    // Confirm dialog
    const data = {
      title: this.translations.deleteConfirmationTitle,
      message: this.translations.deleteConfirmationMessage,
      confirmButtonText: this.translations.delete
    };

    const confirmDialogComponent = this.dialogManager.openDialog(ConfirmDialogComponent, Dialogs.confirmDialog, data);

    confirmDialogComponent.afterClosed().subscribe(result => {
      if (result) {
        // Supprime l'élément aprés confirmation
        this.customWebSearchService.deleteCustomWebSearches(customWebSearch.id).then(() => {
          this.getWebSearchSocialNetworks();
          this.utilHelper.showNotification('deleteWithSuccess');
        }).catch(() => {
          this.utilHelper.showNotification('error', false);
        });
      }
    });
  }

  // Construit les champs du modèle dynamiquement
  private createFormFields(create = true): void {
    this.fieldsConfig = [];
    let validations = [];

    if (create) {
      this.fieldsModel = this.fieldsModel.filter(field => field !== 'translationId');
    } else {
      if (!this.fieldsModel.includes('translationId')) {
        this.fieldsModel.push('translationId');
      }
    }

    this.fieldsModel.forEach(field => {
      const inputType = this.hiddenFields.includes(field) ? 'hidden' : (field === 'order') ? 'number' : 'text';

      validations = [this.utilHelper.getValidator('required')];
      if (field === 'order') {
        validations.push(this.utilHelper.getValidator('min'));
      }

      this.fieldsConfig.push(
        {
          type: 'input',
          inputType,
          label: this.translations[field] ?? `*${field}`,
          name: field,
          validations: this.hiddenFields.includes(field) ? [] : validations,
          condition: null
        }
      );
    });
  }

  // Obtient la liste des moteurs de recherches personnalisés
  private getWebSearchSocialNetworks(): void {
    this.customWebSearchService.getCustomWebSearches().then(
      response => {
        this.webSearchSocialNetworks = response.sort((arg1, arg2) => (arg1.order > arg2.order) ? 1 : -1);
      }
    );
  }
}
