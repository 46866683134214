import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { AgencyModel, DataDepthLevel, IndividualAgencyModel, OrganizationService, QueryableModel, SortOrder } from 'common-services';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { UtilHelper } from 'src/app/shared/helpers/util.helper';

@Component({
  selector: 'app-user-agencies',
  templateUrl: './user-agencies.component.html',
  styleUrls: ['./user-agencies.component.scss']
})
export class UserAgenciesComponent implements OnInit {
  @Input() userGuid: string;
  @Input() individualId: number;

  public parentAgency: FormControl;
  public managedAgency: FormControl;
  // Liste des agences gérées
  public managedAgencies: Array<AgencyModel> = [];
  // Liste des agences qui ont la possibilité d'être le parent + le parent de l'agence actuelle
  public potentialParentAgencies: Array<AgencyModel> = [];
  // Liste des agences à gérer
  public potentialManagedAgencies: Array<AgencyModel> = [];
  public filteredOptions: Observable<any[]>;
  public parentAgencyId: number;

  private managedAgenciesIds: Array<number> = [];

  constructor(
    private readonly organizationService: OrganizationService,
    private readonly utilHelper: UtilHelper
  ) { }

  ngOnInit(): void {
    this.parentAgency = new FormControl('');
    this.managedAgency = new FormControl('');

    // Obtenir les listes des agences
    this.getUserAgencies();
    // Filtrer les agences à gérer
    this.filteredOptions = this.managedAgency.valueChanges.pipe(
      startWith(''),
      map(value => this.filterAgencies(value)),
    );
  }

  public selectedParentAgency(agencyId: number) {
    const individualAgencyModel: IndividualAgencyModel = {
      agencyId,
      individualId: this.individualId,
      default: true
    };
    // Affecter l'utilisateur à la nouvelle agence principal et le supprimer de son agence principale précédente
    this.organizationService.addIndividualToAgency(agencyId, this.individualId, individualAgencyModel)
      .then(async () => {
        // Vérifier si l'agence a une agence mère
        if (this.parentAgencyId) {
          await this.organizationService.deleteIndividualFromAgency(this.individualId, this.parentAgencyId);
        }
        this.parentAgencyId = agencyId;
        this.getUserAgencies();
        this.utilHelper.showNotification('updateSuccess');
      })
      .catch(() => this.utilHelper.showNotification('error', false));
  }

  public async addManagedAgency(agencyId: number) {
    const individualAgency: IndividualAgencyModel = {
      agencyId,
      individualId: this.individualId,
      default: false
    };
    this.potentialManagedAgencies = this.potentialManagedAgencies.filter(agency => agency.id !== agencyId);

    await this.organizationService.addIndividualToAgency(agencyId, this.individualId, individualAgency)
      .then(() => {
        this.managedAgency.reset('');
      })
      .catch(() => this.utilHelper.showNotification('error', false));
    // Recalculer les listes des agences
    this.getUserAgencies();
  }

  public async deleteUserFromAgency(agencyId: number) {
    // Supprimer l'agence séléctionner de la liste des agences gérées
    this.managedAgencies = this.managedAgencies.filter(el => el.id !== agencyId);

    await this.organizationService.deleteIndividualFromAgency(this.individualId, agencyId)
      .catch(() => this.utilHelper.showNotification('error', false));
    // Recalculer les listes des agences
    this.getUserAgencies();
  }

  private getUserAgencies() {
    const query: QueryableModel = {
      whereExpression: `a => a.individual.userGuid = "${this.userGuid}"`,
      dataDepthLevel: DataDepthLevel.WithSubObjectsAndSubLists,
      sortExpression: 'agency.label',
      sortOrder: SortOrder.Ascending.toString(),
      page: 0,
      pageSize: 10000
    };

    this.organizationService.findIndividualAgenciesByQueryable(query)
      .then(result => {
        this.managedAgencies = [];
        this.managedAgenciesIds = [];
        result.items.map(
          el => {
            if (!el.default) {
              // Grouper les agences gérée par l'utilisateur(agence principale non inclue)
              this.managedAgencies.push(el.agency);
              this.managedAgenciesIds.push(el.agency.id);
            } else {
              this.parentAgencyId = el.agency.id;
            }
          }
        );
        this.getUserPotentialParentAgencies();
        // Récupérer les agences à gérées
        if (this.parentAgencyId) {
          this.getUserPotentialManagedAgencies();
        }
      });
  }

  private getUserPotentialParentAgencies() {
    // Obtenir l'agence principale et la liste des agences qui ne sont pas gérées
    const query: QueryableModel = {
      whereExpression: this.managedAgencies.length > 0 ? `a => !(a.id in (${this.managedAgenciesIds.join(',')}))` : `a => true`,
      dataDepthLevel: DataDepthLevel.WithSubObjectsAndSubLists,
      sortExpression: 'label',
      sortOrder: SortOrder.Ascending.toString(),
      page: 0,
      pageSize: 10000
    };

    this.organizationService.findAgenciesByQueryable(query)
      .then(result => {
        this.potentialParentAgencies = result.items;
        if (this.parentAgencyId) {
          this.parentAgency.setValue(this.parentAgencyId);
        }
      });
  }

  private getUserPotentialManagedAgencies() {
    // Grouper les ids des agences gérées + l'agence principale
    const groupedManagedAgenciesIds = this.managedAgenciesIds;
    groupedManagedAgenciesIds.push(this.parentAgencyId);
    // Obtenir la liste des agences sans les agences gérées et l'agence principale
    const query: QueryableModel = {
      whereExpression: `a => !(a.id in (${groupedManagedAgenciesIds.join(',')}))`,
      dataDepthLevel: DataDepthLevel.WithSubObjectsAndSubLists,
      sortExpression: 'label',
      sortOrder: SortOrder.Ascending.toString(),
      page: 0,
      pageSize: 10000
    };

    this.managedAgency.disable();
    this.organizationService.findAgenciesByQueryable(query)
      .then(result => {
        this.potentialManagedAgencies = result.items;
        this.managedAgency.enable();
      });
  }

  private filterAgencies(value: string) {
    return this.potentialManagedAgencies.filter(option => option.label.toLowerCase().includes(value.toLowerCase()));
  }
}
