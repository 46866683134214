<div class="modal-wrapper">
  <div class="modal-haeder">
    <mat-icon class="button-close float-right" (click)="close()">close</mat-icon>
    <h1 mat-dialog-title>
      {{data.title}}
    </h1>
  </div>
  <div class="modal-body">
    <app-dynamic-form (save)="onSave($event)" [fieldsConfig]="data.fieldsConfig" [displayTitle]="false">
    </app-dynamic-form>
  </div>
</div>
