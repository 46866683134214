<form class="dynamic-form" [formGroup]="formGroup" (submit)="onSubmit($event)"
  [ngClass]="{ 'has-blocks' : fieldsetConfig?.length }">

  <h1 *ngIf="fieldsConfig[0]?.label && displayTitle" class="setting-title">
    {{fieldsConfig[0].label}}
  </h1>

  <div class="block-settings">
    <div *ngIf="( fieldsConfig.length > 0 ); else elseBlock;">
      <div *ngFor="let field of fieldsConfig; let last = last;" class="dynamic-field-wrapper" [ngClass]="{
        'start-group' : ( buildFieldSet(field).position === 'start-group' )
      }" data-title="{{ buildFieldSet(field).title }}" id="{{field.name}}-wrapper">
        <app-dynamic-field *ngIf="displayField(field)" [fieldConfig]="field" [formGroup]="formGroup"></app-dynamic-field>

        <hr *ngIf="!last && ( buildFieldSet(field).position === 'end-group' || buildFieldSet(field).position === 'one-field-group' )">
      </div>
    </div>
    <ng-template #elseBlock>
      <app-empty-data></app-empty-data>     
    </ng-template>
  </div>

  <div class="block-settings-footer">
    <button *ngIf="fieldsConfig.length" disabled="{{!isValid()}}" mat-raised-button class="btn-eolia btn-primary">
      <mat-icon>check</mat-icon>
      {{ 'save' | translate }}
    </button>
  </div>

</form>
