import { Component, ComponentFactoryResolver, Input, OnInit, ViewContainerRef } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ComponentMapperModel } from 'src/app/shared/models/component-mapper.model';
import { FieldConfigModel } from 'src/app/shared/models/field.model';
import { AutoCompleteComponent } from '../form-fields/autocomplete/autocomplete.component';
import { DateComponent } from '../form-fields/date/date.component';
import { InputComponent } from '../form-fields/input/input.component';
import { MultipleCheckboxComponent } from '../form-fields/multiple-checkbox/multiple-checkbox.component';
import { MultipleSelectComponent } from '../form-fields/multiple-select/multiple-select.component';
import { SelectComponent } from '../form-fields/select/select.component';
import { SwitcherComponent } from '../form-fields/switcher/switcher.component';

@Component({
  selector: 'app-dynamic-field',
  template: ''
})
export class DynamicFieldComponent implements OnInit {
  @Input() fieldConfig: FieldConfigModel;
  @Input() formGroup: FormGroup;

  private readonly componentMapper: ComponentMapperModel;
  private componentRef: any;

  constructor(
    private readonly resolver: ComponentFactoryResolver,
    private readonly container: ViewContainerRef
  ) {
    this.componentMapper = {
      input: InputComponent,
      select: SelectComponent,
      multipleSelect: MultipleSelectComponent,
      switcher: SwitcherComponent,
      dateTime: DateComponent,
      multipleCheckbox: MultipleCheckboxComponent,
      autocomplete: AutoCompleteComponent
    };
  }

  ngOnInit(): void {
    const factory = this.resolver.resolveComponentFactory(
      this.componentMapper[this.fieldConfig.type]
    );

    this.componentRef = this.container.createComponent(factory);
    this.componentRef.instance.fieldConfig = this.fieldConfig;
    this.componentRef.instance.formGroup = this.formGroup;
  }
}
