import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FieldConfigModel } from 'src/app/shared/models/field.model';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SelectComponent implements OnInit {
  public fieldConfig: FieldConfigModel;
  public formGroup: FormGroup;

  ngOnInit(): void {
    if (this.fieldConfig?.value) {
      this.formGroup.patchValue(this.fieldConfig.value);
    }
  }
}
