<div class="modal-wrapper">
  <div class="modal-haeder">
    <h1 mat-dialog-title>
      {{data.title}}
    </h1>
  </div>

  <div class="modal-body" mat-dialog-content>
    <div class="content">
      {{ data.message }}
    </div>
  </div>

  <div mat-dialog-actions class="modal-footer">
    <button (click)="onConfirm(false)" class="btn-eolia btn-secondary">
      {{'cancel' | translate }}
    </button>
    <button (click)="onConfirm(true)" class="align-end btn-eolia btn-primary">
      {{ data.confirmButtonText }}
    </button>
  </div>
</div>
