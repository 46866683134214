<div id="agencies-list">
    <div *ngIf="isList; else elseBlock;">
        <app-editable-table [sortColumn]="sortColumn" [pageIndex]="currentPage" [search]="search"
            [sortByColumns]="sortByColumns" [displayedColumns]="displayedColumns" [data]="agencies"
            [hasTranslation]="false" [title]="title" [searchable]="true" [hasPagination]="true" [rowCount]="rowCount"
            (create)="onCreate()" (edit)="onEdit($event)" (filter)="onSearch($event)" (paginate)="onPaginate($event)"
            (delete)="onDelete($event)" (sort)="onSort($event)"></app-editable-table>
    </div>
    <ng-template #elseBlock>
        <h1 class=" setting-title">
            <mat-icon (click)="isList = true" class="material-icons-outlined">
                arrow_back
            </mat-icon>
            <span>{{ agency ? agency.label : ('new' | translate) }}</span>
        </h1>
        <app-agency (backToListing)="backToListing()" [agency]="agency">
        </app-agency>
    </ng-template>
</div>
