<h1 class="setting-title">
    {{ 'formConfiguration' | translate }}
</h1>
<mat-tab-group mat-align-tabs="start" dynamicHeight="true">
    <mat-tab label="{{ 'candidate' | translate  | uppercase }}">
      <ng-template matTabContent>
        <app-dynamic-fields-entities [entity]="1">
        </app-dynamic-fields-entities>
      </ng-template>
    </mat-tab>
    <mat-tab label="{{ 'position' | translate  | uppercase }}">
      <ng-template matTabContent>
        <app-dynamic-fields-entities [entity]="0"></app-dynamic-fields-entities>
      </ng-template>
    </mat-tab>
    <mat-tab label="{{ 'formation' | translate  | uppercase }}">
      <ng-template matTabContent>
        <app-dynamic-fields-entities [entity]="4"></app-dynamic-fields-entities>
      </ng-template>
    </mat-tab>
    <mat-tab label="{{ 'experience' | translate  | uppercase }}">
      <ng-template matTabContent>
        <app-dynamic-fields-entities [entity]="3"></app-dynamic-fields-entities>
      </ng-template>
    </mat-tab>
    <mat-tab label="{{ 'customer' | translate  | uppercase }}">
      <ng-template matTabContent>
        <app-dynamic-fields-entities [entity]="2"></app-dynamic-fields-entities>
      </ng-template>
    </mat-tab>
</mat-tab-group>
