<div>
    <h1 class="setting-title">
        <mat-icon (click)="updateUser.emit()">arrow_back</mat-icon>
        <span>
            {{userToEdit?.firstName}} {{userToEdit?.lastName}}
        </span>
    </h1>
    <div class="tab-group">
        <mat-tab-group>
            <mat-tab label="{{ 'informations' | translate}}">
                <app-edit-user-information [userGuid]="userToEdit.userGuid"></app-edit-user-information>
            </mat-tab>
            <mat-tab label="{{ 'agencies' | translate}}">
                <app-user-agencies [userGuid]="userToEdit.userGuid" [individualId]="userToEdit.id"></app-user-agencies>
            </mat-tab>
        </mat-tab-group>
    </div>
</div>
