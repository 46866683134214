<form id="agency-form" [formGroup]="agencyForm">
    <div class="container block-settings">
        <div class="row">
            <div class="col-sm-12 col-md-6 col-lg-6">
                <mat-form-field>
                    <label>
                        {{ 'agencyName' | translate}} <span class="required-field">*</span>
                    </label>
                    <input matInput [formControl]="agencyForm.controls.label">
                    <mat-error *ngIf="agencyForm.controls.label.hasError('required')">
                        {{ 'requiredField' | translate}}
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-6">
                <mat-form-field>
                    <label>
                        {{ 'code' | translate}} <span class="required-field">*</span>
                    </label>
                    <input matInput [formControl]="agencyForm.controls.code">
                    <mat-error *ngIf="agencyForm.controls.code.hasError('required')">
                        {{ 'requiredField' | translate}}
                    </mat-error>
                    <mat-error *ngIf="agencyForm.controls.code.hasError('maxlength')">
                        {{ maxLengthMessage }}
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-6">
                <mat-form-field>
                    <label>
                        {{ 'parentAgency' | translate}} <span class="required-field">*</span>
                    </label>
                    <mat-select [formControl]="agencyForm.controls.parentId">
                        <mat-option *ngFor="let agency of agencies" [value]="agency.id">{{agency.label}}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="agencyForm.controls.parentId.hasError('required')">
                        {{ 'requiredField' | translate}}
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
    </div>
    <div class="col-12 block-settings-footer">
        <button mat-raised-button class="btn-eolia btn-primary" (click)="saveAgency()"
            [disabled]="!agencyForm.dirty || isSaving">
            <mat-icon>check</mat-icon>{{ 'save' | translate}}
        </button>
    </div>
</form>

<app-individual-agency-table *ngIf="agency" [agency]="agency"></app-individual-agency-table>
