<div id="home">
  <mat-progress-bar *ngIf="authByToken; else elseBlockAuthByToken" mode="indeterminate"></mat-progress-bar>

  <ng-template #elseBlockAuthByToken>
    <div *ngIf="isLoggedIn; else elseblock;">

      <div id="header">
        <span class="btn-menu" (click)="toggleSidebar()">
          <mat-icon *ngIf="hideSidebar; else elseMenuIconblock;">menu</mat-icon>
          <ng-template #elseMenuIconblock>
            <mat-icon>close</mat-icon>
          </ng-template>
        </span>
        <img alt="Eolia Software" class="logo" width="32" height="40" src="/assets/img/logo_eolia.png" />
        <span class="logout" (click)="onLogout()">
          <mat-icon>power_settings_new</mat-icon>
        </span>
      </div>

      <mat-drawer-container id="content" class="logged" [ngClass]="{ 'hide-sidebar' : hideSidebar }">

        <mat-drawer class="side-bar-wrapper" mode="side" opened>
          <app-sidebar></app-sidebar>
        </mat-drawer>

        <mat-drawer-content>
          <div class="main scroll-wrapper">
            <div class="scroll">
              <div class="content-wrapper" id="content-wrapper">
                <div class="overlay-settings" (click)="toggleSidebar()"></div>
                <router-outlet></router-outlet>
              </div>
            </div>
          </div>
        </mat-drawer-content>

      </mat-drawer-container>

    </div>
    <ng-template #elseblock>

      <mat-drawer-container id="content">
        <mat-drawer-content>
          <div class="main">
            <app-login></app-login>
          </div>
        </mat-drawer-content>
      </mat-drawer-container>

    </ng-template>
  </ng-template>
</div>
