import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Dialogs } from '../enums/dialogs-enum';
import { DialogHelper } from '../helpers/dialog.helper';

@Injectable({
  providedIn: 'root',
})
export class DialogsManager {

  private readonly dialogHelper: DialogHelper;

  constructor(
    private readonly dialog: MatDialog,
  ) {
    this.dialogHelper = new DialogHelper();
  }

  public openDialog<T>(component: any, dialog: Dialogs, data?: any): T | any {
    const dialogConfig = this.dialogHelper.getDialogConfig(dialog);
    if (!dialogConfig) {
      return undefined;
    }
    if (data) {
      dialogConfig.data = data;
    }

    return this.dialog.open(component, dialogConfig);
  }

  public closeDialog<T>(dialogRef: MatDialogRef<T>): void {
    if (dialogRef) {
      dialogRef.close();
    }
  }
}
