import { Component, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  DataDepthLevel,
  DynamicFieldConfigurationModel,
  DynamicFieldEntity,
  DynamicFieldModel,
  DynamicFieldService,
  QueryableModel,
  SortOrder
} from 'common-services';
import { Subscription } from 'rxjs';
import { ConfirmDialogComponent } from 'src/app/generic-components/confirm-dialog/confirm-dialog.component';
import { EditableTableComponent } from 'src/app/generic-components/editable-table/editable-table.component';
import { FormDialogComponent } from 'src/app/generic-components/form-dialog/form-dialog.component';
import { Dialogs } from 'src/app/shared/enums/dialogs-enum';
import { UtilHelper } from 'src/app/shared/helpers/util.helper';
import { DialogsManager } from 'src/app/shared/managers/dialog.manager';
import { FieldConfigModel } from 'src/app/shared/models/field.model';
import { FormDialogModel } from 'src/app/shared/models/form-dialog.model';
import { pageSize } from 'src/globals';

@Component({
  selector: 'app-dynamic-fields-entities',
  templateUrl: './dynamic-fields-entities.component.html',
  styleUrls: ['./dynamic-fields-entities.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DynamicFieldsEntitiesComponent implements OnInit {
  @ViewChild('editableTable', { static: true }) editableTable: EditableTableComponent<DynamicFieldModel>;
  @Input() entity: DynamicFieldEntity;

  public readonly displayedColumns: string[];
  public data: Array<DynamicFieldConfigurationModel> = [];
  public pageSize = pageSize;
  public rowCount: number;

  private readonly subscription: Subscription;
  private dynamicFields: DynamicFieldModel[];
  private dynamicFieldsConfigToPaginate: DynamicFieldConfigurationModel[];
  private dynamicFieldsConfig: DynamicFieldConfigurationModel[];
  private fieldsConfig: FieldConfigModel[];
  private formFields: string[];
  private translations: Record<string, string>;

  constructor(
    private readonly dialogManager: DialogsManager,
    private readonly dynamicFieldService: DynamicFieldService,
    private readonly translateService: TranslateService,
    private readonly utilHelper: UtilHelper
  ) {
    this.displayedColumns = ['label', 'order', 'required', 'visible'];
    this.formFields = ['fieldId', 'order', 'required', 'visible'];
    this.subscription = new Subscription();
  }

  ngOnInit(): void {
    const translateSubscription = this.translateService.get(
      this.displayedColumns.concat(
        [
          'new',
          'update',
          'deleteConfirmationTitle',
          'deleteConfirmationMessage',
          'delete',
          'fieldName'
        ]
      )
    ).subscribe(translation => {
      this.translations = translation;
    });

    this.subscription.add(translateSubscription);

    this.getDynamicFields();
    this.getDynamicFieldConfigurationsByEntity();
  }

  // Ajoute une configuration
  public onCreate() {
    this.buildFormFields();

    // Les données du popup
    const data = {
      title: this.translations.new,
      fieldsConfig: this.fieldsConfig
    } as FormDialogModel;

    // Ouvre la popup
    const formDialogComponent = this.dialogManager.openDialog(FormDialogComponent, Dialogs.formDialog, data).componentInstance;
    formDialogComponent.save.subscribe((config: DynamicFieldConfigurationModel) => {
      config.id = 0;
      config.entity = this.entity;
      this.dynamicFieldService.addDynamicFieldConfiguration(config.fieldId, config).then(() => {
        this.getDynamicFieldConfigurationsByEntity();
        formDialogComponent.dialogRef.close();
        this.utilHelper.showNotification('addSuccess');
      }).catch(() => {
        this.utilHelper.showNotification('error', false);
      });
    });
  }

  // Ajoute une configuration
  public onEdit(configToUpdate: DynamicFieldConfigurationModel) {
    this.buildFormFields(true);

    // Remplire les champs du formulaire
    if (configToUpdate) {
      this.fieldsConfig.forEach(field => {
        Object.entries(configToUpdate).forEach(([key, value]) => {
          if (field.name === key) {
            field.value = value;
          }
        });
      });
    }

    // Les données du popup
    const data = {
      title: this.translations.update,
      fieldsConfig: this.fieldsConfig
    } as FormDialogModel;

    // Ouvre la popup
    const formDialogComponent = this.dialogManager.openDialog(FormDialogComponent, Dialogs.formDialog, data).componentInstance;
    formDialogComponent.save.subscribe((config: DynamicFieldConfigurationModel) => {
      this.dynamicFieldService.updateDynamicFieldConfiguration(configToUpdate.fieldId, configToUpdate.id, config).then(() => {
        this.getDynamicFieldConfigurationsByEntity();
        formDialogComponent.dialogRef.close();
        this.utilHelper.showNotification('updateSuccess');
      }).catch(() => {
        this.utilHelper.showNotification('error', false);
      });
    });
  }

  // Supprime une configuration
  public onDelete(configuration: DynamicFieldConfigurationModel): void {
    // Confirm dialog
    const data = {
      title: this.translations.deleteConfirmationTitle,
      message: this.translations.deleteConfirmationMessage,
      confirmButtonText: this.translations.delete
    };

    const confirmDialogComponent = this.dialogManager.openDialog(ConfirmDialogComponent, Dialogs.confirmDialog, data);
    confirmDialogComponent.afterClosed().subscribe(result => {
      if (result) {
        // Supprime l'élément aprés confirmation
        this.dynamicFieldService.deleteDynamicFieldConfiguration(configuration.fieldId, configuration.id).then(() => {
          this.getDynamicFieldConfigurationsByEntity();
          this.utilHelper.showNotification('deleteWithSuccess');
        }).catch(() => {
          this.utilHelper.showNotification('error', false);
        });
      }
    });
  }

  public onPaginate(pageNumber: number) {
    this.data = this.paginate(this.dynamicFieldsConfigToPaginate, pageNumber);
  }

  public onFilter(searchValue: string) {
    const filter = searchValue.toLowerCase();
    this.dynamicFieldsConfigToPaginate = this.dynamicFieldsConfig.filter(df => df.field.label.toLowerCase().includes(filter))
                                         ?? this.dynamicFieldsConfig;
    this.rowCount = this.dynamicFieldsConfigToPaginate.length;
    this.data = this.paginate(this.dynamicFieldsConfigToPaginate, 1);
  }

  private paginate(listToPaginated: DynamicFieldConfigurationModel[], pageNumber: number) {
    return listToPaginated.slice((pageNumber - 1) * this.pageSize, pageNumber * this.pageSize);
  }

  private getDynamicFieldConfigurationsByEntity() {
    this.dynamicFieldService.getAllDynamicFieldConfigurationsByEntity(this.entity).then(data => {
      this.dynamicFieldsConfig = [];
      data.forEach(config => {
        const configuration = config as any;
        configuration.label = config.field?.label ?? '';
        this.dynamicFieldsConfig.push(configuration);
      });

      this.rowCount = data.length;
      this.dynamicFieldsConfigToPaginate = [...this.dynamicFieldsConfig];
      this.data = this.paginate(this.dynamicFieldsConfigToPaginate, 1);
    });
  }

  private getDynamicFields() {
    const query: QueryableModel = {
      whereExpression: '',
      dataDepthLevel: DataDepthLevel.Flat,
      sortExpression: 'label',
      sortOrder: SortOrder.Ascending.toString(),
      page: 0,
      pageSize: 10000
    };

    this.dynamicFieldService.findByQueryable(query).then(data => {
      this.dynamicFields = data.items;
    });
  }

  private buildFormFields(formEdit = false) {
    // Initialiser les champs du formulaire
    this.fieldsConfig = [];

    const formFieldCloned = [...this.formFields];
    if (formEdit) {
      // On supprime le champ FieldId dans le formulaire de modification
      formFieldCloned.shift();
    }

    formFieldCloned.forEach(field => {
      const fieldConfig = {
        name: field,
        label: this.translations[field],
        type: 'switcher',
        condition: null
      } as FieldConfigModel;

      switch (field) {
        case 'order':
          fieldConfig.type = 'input';
          fieldConfig.inputType = 'number';
          fieldConfig.validations = [this.utilHelper.getValidator('min')];
          break;
        case 'fieldId':
          fieldConfig.type = 'autocomplete';
          fieldConfig.inputType = 'text';
          fieldConfig.label = this.translations.fieldName;
          fieldConfig.options = this.dynamicFields.map(df => ({ key: df.id, value: df.label }));
          fieldConfig.validations = [this.utilHelper.getValidator('required')];
          break;
      }

      this.fieldsConfig.push(fieldConfig);
    });
  }
}
