<!-- translate-button -->
<div class="translate-button">
    <div class="list-languages" *ngIf="showLanguages">
        <ul>
            <li *ngFor="let code of codeLanguages; let last=last;">
                <img title="{{code}}" alt="{{code}}" width="20" height="13" class="flag" src="{{flagUri(code)}}"/>
            </li>
        </ul>
    </div>
    <mat-icon class="translate" (click)="openModal()">translate</mat-icon>
</div>
<!-- /translate-button -->

<ng-container *ngTemplateOutlet="template"></ng-container>
<ng-template #template>
    <!-- list-of-translations -->
    <div class="list-translations">
        <div class="modal-wrapper">
            <div class="modal-haeder">
                <mat-icon class="button-close float-right" (click)="closeModal()">close</mat-icon>
                <h1 mat-dialog-title>
                    {{'translations' | translate}} ({{ modelToTranslate['label'] }})
                </h1>
            </div>

            <div class="modal-body">
                <div class="table-wrapper">
                    <table mat-table [dataSource]="dataSource" *ngIf="dataSource.data.length > 0; else elseBlock;">
                        <caption></caption>
                        <!-- language-column -->
                        <ng-container [matColumnDef]="'Language'">
                            <th scope="col" mat-header-cell *matHeaderCellDef>
                                <span>
                                    {{ 'language' | translate }}
                                </span>
                            </th>
                            <td mat-cell *matCellDef="let element">
                                <ng-container
                                    *ngIf="isEditMode && elementToEdit === element.languageCultureInfo; else elseblock">
                                    <mat-select placeholder="language" name="language" [(ngModel)]="element.languageId">
                                        <mat-option [value]="element.language.id">{{ element.language.label }}
                                        </mat-option>
                                    </mat-select>
                                </ng-container>
                                <ng-template #elseblock>
                                    <span>
                                        {{element.language.label}}
                                    </span>
                                </ng-template>
                            </td>
                        </ng-container>
                        <!-- /language-column -->

                        <!-- translationsValue-column -->
                        <ng-container [matColumnDef]="'TranslationValue'">
                            <th scope="col" mat-header-cell *matHeaderCellDef>
                                <span>
                                    {{ 'translationValue' | translate }}
                                </span>
                            </th>
                            <td mat-cell *matCellDef="let element">
                                <ng-container
                                    *ngIf="isEditMode && elementToEdit === element.languageCultureInfo; else elseblock">
                                    <mat-form-field>
                                        <input matInput type="text" name="translationValue"
                                            [(ngModel)]="element.translationValue"
                                            (keyup.enter)="onSaveUpdate(element)" />
                                    </mat-form-field>
                                </ng-container>
                                <ng-template #elseblock>
                                    <span>
                                        {{element.translationValue}}
                                    </span>
                                </ng-template>
                            </td>
                        </ng-container>
                        <!-- /translationsValue-column -->

                        <!-- actions-column -->
                        <ng-container [matColumnDef]="'Actions'">
                            <th scope="col" mat-header-cell *matHeaderCellDef>
                                <span class="float-right">{{ 'actions' | translate }}</span>
                            </th>
                            <td mat-cell *matCellDef="let element">
                                <div class="actions">

                                    <ng-container
                                        *ngIf="isEditMode && elementToEdit === element.languageCultureInfo; else elseblock">
                                        <mat-icon class="save" (click)="onSaveUpdate(element)">done</mat-icon>
                                        <mat-icon class="cancel" (click)="onCancel()">clear</mat-icon>
                                    </ng-container>
                                    <ng-template #elseblock>
                                        <mat-icon class="icon-actions" [matMenuTriggerFor]="menu">more_horiz</mat-icon>
                                        <mat-menu class="eolia-mat-menu" #menu="matMenu">
                                            <!-- edit-button -->
                                            <button mat-menu-item class="action-wrapper gray" (click)="onEdit(element.languageCultureInfo)">
                                                <mat-icon class="edit">edit</mat-icon>
                                                {{ 'modify' | translate }}
                                            </button>
                                            <!-- /edit-button -->

                                            <!-- delete-button -->
                                            <button mat-menu-item class="action-wrapper danger" (click)="onDelete(element)">
                                                <mat-icon class="remove">delete</mat-icon>
                                                {{ 'delete' | translate }}
                                            </button>
                                            <!-- /delete-button -->
                                        </mat-menu>
                                    </ng-template>
                                </div>
                            </td>
                        </ng-container>
                        <!-- actions-column -->

                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </table>

                    <ng-template #elseBlock>
                        <app-empty-data></app-empty-data>     
                    </ng-template>
                    
                </div>
            </div>

            <div class="modal-footer">
                <button class="btn-eolia btn-primary" mat-stroked-button [disabled]="!addTranslate"
                    (click)="onCreate()">
                    <mat-icon>add</mat-icon>
                    {{ 'new' | translate }}
                </button>
            </div>
        </div>
    </div>
    <!-- /list-of-translations -->
</ng-template>
