import { LogLevel } from 'common-services';

export const environment = {
  productName: 'Parameters Manager',
  baseUrl: 'https://msinternal-recette.eolia-software.com',
  production: true,
  log: {
    minimumLevel: LogLevel.Error
  }
};
