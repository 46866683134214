import { DragDropModule } from '@angular/cdk/drag-drop';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { GenericComponentsModule } from '../generic-components/generic-components.module';
import { AngularMaterialsModule } from '../shared/angular-materials.module';
import { AgenciesListComponent } from './agencies/agencies-list/agencies-list.component';
import { AgencyComponent } from './agencies/agency/agency.component';
import { IndividualAgencyTableComponent } from './agencies/individual-agency-table/individual-agency-table.component';
import { ApplicativeAreaParameterComponent } from './applicative-area-parameter/applicative-area-parameter.component';
import { CivilityComponent } from './civility/civility.component';
import { CnilActionTypeComponent } from './cnil-action-type/cnil-action-type.component';
import { CnilExcludedOriginComponent } from './cnil-excluded-origin/cnil-excluded-origin.component';
import { CustomWebSearchComponent } from './custom-web-search/custom-web-search.component';
import { DynamicFieldsEntitiesComponent } from './dynamic-fields/configurations/dynamic-fields-entities/dynamic-fields-entities.component';
import { EntitiesComponent } from './dynamic-fields/configurations/entities/entities.component';
import { DynamicFieldFormComponent } from './dynamic-fields/dynamic-fields-form/dynamic-fields-form.component';
import { DynamicFieldListComponent } from './dynamic-fields/dynamic-fields-list/dynamic-fields-list.component';
import {
    DynamicFieldPredefinedValuesComponent
} from './dynamic-fields/dynamic-fields-predefined-values/dynamic-fields-predefined-values.component';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { NotFoundPageComponent } from './not-found-page/not-found-page.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { AddUserComponent } from './users/add-user/add-user.component';
import { EditUserInformationComponent } from './users/edit/edit-user-information/edit-user-information.component';
import { EditUserComponent } from './users/edit/edit-user/edit-user.component';
import { UserAgenciesComponent } from './users/edit/user-agencies/user-agencies.component';
import { UsersListComponent } from './users/users-list/users-list.component';

// Ajoutez vos modules ici
const modules = [
    AngularMaterialsModule,
    BrowserModule,
    DragDropModule,
    FormsModule,
    GenericComponentsModule,
    ReactiveFormsModule,
    RouterModule,
    TranslateModule
];

// Ajoutez vos components ici
const components = [
    AddUserComponent,
    AgenciesListComponent,
    AgencyComponent,
    ApplicativeAreaParameterComponent,
    CnilExcludedOriginComponent,
    CnilActionTypeComponent,
    CivilityComponent,
    CustomWebSearchComponent,
    DynamicFieldsEntitiesComponent,
    DynamicFieldFormComponent,
    DynamicFieldListComponent,
    DynamicFieldPredefinedValuesComponent,
    EditUserComponent,
    EditUserInformationComponent,
    EntitiesComponent,
    IndividualAgencyTableComponent,
    LoginComponent,
    HomeComponent,
    NotFoundPageComponent,
    SidebarComponent,
    UsersListComponent,
    UserAgenciesComponent
];

@NgModule({
    imports: [
        ...modules
    ],
    exports: [
        ...modules,
        ...components
    ],
    declarations: [
        ...components
    ]
})
export class PagesModule { }
