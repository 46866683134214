import { Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CivilityModel, CivilityService } from 'common-services';
import { Subscription } from 'rxjs';
import { ConfirmDialogComponent } from 'src/app/generic-components/confirm-dialog/confirm-dialog.component';
import { EditableTableComponent } from 'src/app/generic-components/editable-table/editable-table.component';
import { FormDialogComponent } from 'src/app/generic-components/form-dialog/form-dialog.component';
import { Dialogs } from 'src/app/shared/enums/dialogs-enum';
import { TranslatableDomainModelName } from 'src/app/shared/enums/translatable-domain-model-name.enum';
import { UtilHelper } from 'src/app/shared/helpers/util.helper';
import { DialogsManager } from 'src/app/shared/managers/dialog.manager';
import { FieldConfigModel } from 'src/app/shared/models/field.model';
import { FormDialogModel } from 'src/app/shared/models/form-dialog.model';

@Component({
  selector: 'app-civility',
  templateUrl: './civility.component.html',
  styleUrls: ['./civility.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CivilityComponent implements OnInit, OnDestroy {
  @ViewChild('editableTable', { static: true }) editableTable: EditableTableComponent<CivilityModel>;

  public civilities: Array<CivilityModel> = [];
  public translatableDomainModelName: string;
  public readonly displayedColumns: string[];
  public title: string;

  private translations: Record<string, string>;
  private fieldsConfig: FieldConfigModel[];
  private fieldsModel: string[];
  private readonly subscription: Subscription;
  private readonly hiddenFields: string[];

  constructor(
    private readonly civilityService: CivilityService,
    private readonly dialogManager: DialogsManager,
    private readonly translateService: TranslateService,
    private readonly utilHelper: UtilHelper
  ) {
    this.subscription = new Subscription();
    this.displayedColumns = ['label', 'code', 'order'];
    this.hiddenFields = ['id', 'translationId'];
    this.fieldsModel = ['id', 'label', 'code', 'order', 'translationId'];
    this.translatableDomainModelName = TranslatableDomainModelName.civilityModel;
    this.subscription = new Subscription();
  }

  ngOnInit(): void {
    this.getCivilities();
    const translateSubscription = this.translateService.get(
      [
        'new',
        'update',
        'deleteConfirmationTitle',
        'deleteConfirmationMessage',
        'delete',
        'label',
        'code',
        'order',
        'civilities'
      ]
    ).subscribe(translation => {
      this.translations = translation;
      this.title = translation.civilities;
    });
    this.subscription.add(translateSubscription);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  // Crée une civilité
  public onCreate(): void {
    this.createFormFields();

    // On initialise les valeurs des champs
    this.fieldsConfig.forEach(field => {
      field.value = (field.label === 'order') ? this.utilHelper.incrementOrder(this.civilities.map(civility => civility.order)) : '';
    });

    // Les données du popup
    const data = {
      title: this.translations.new,
      fieldsConfig: this.fieldsConfig
    } as FormDialogModel;

    // Ouvre la popup
    const formDialogComponent = this.dialogManager.openDialog(FormDialogComponent, Dialogs.formDialog, data).componentInstance;
    formDialogComponent.save.subscribe((civilityModel: CivilityModel) => {
      civilityModel.id = 0;
      this.civilityService.create(civilityModel).then(() => {
        this.getCivilities();
        formDialogComponent.dialogRef.close();
        this.utilHelper.showNotification('addSuccess');
      }).catch(() => {
        this.utilHelper.showNotification('error', false);
      });
    });
  }

  // Met à jour une civilité
  public onEdit(civilityModel: CivilityModel): void {
    this.createFormFields(false);
    // Remplire les champs
    if (civilityModel) {
      this.fieldsConfig.forEach(field => {
        Object.entries(civilityModel).forEach(([key, value]) => {
          if (field.name === key) {
            field.value = value;
          }
        });
      });
    }
    // traduction des champs pour le formBuilder
    // Les données du popup
    const data = {
      title: `${this.translations.update} (${civilityModel.label})`,
      fieldsConfig: this.fieldsConfig
    } as FormDialogModel;

    // Ouvre la popup
    const formDialogComponent = this.dialogManager.openDialog(FormDialogComponent, Dialogs.formDialog, data).componentInstance;
    formDialogComponent.save.subscribe((civility: CivilityModel) => {
      this.civilityService.update(civility).then(() => {
        this.getCivilities();
        formDialogComponent.dialogRef.close();
        this.utilHelper.showNotification('updateSuccess');
      }).catch(() => {
        this.utilHelper.showNotification('error', false);
      });
    });
  }

  // Supprime une civilité
  public onDelete(civility: CivilityModel): void {
    // Confirm dialog
    const data = {
      title: this.translations.deleteConfirmationTitle,
      message: this.translations.deleteConfirmationMessage,
      confirmButtonText: this.translations.delete
    };

    const confirmDialogComponent = this.dialogManager.openDialog(ConfirmDialogComponent, Dialogs.confirmDialog, data);
    confirmDialogComponent.afterClosed().subscribe(result => {
      if (result) {
        // Supprime l'élément aprés confirmation
        this.civilityService.delete(civility.id).then(() => {
          this.getCivilities();
          this.utilHelper.showNotification('deleteWithSuccess');
        }).catch(() => {
          this.utilHelper.showNotification('error', false);
        });
      }
    });
  }

  // Obtient les civilités
  private getCivilities(): void {
    this.civilityService.getCivilities().then(civilities => {
      this.civilities = civilities.sort((arg1, arg2) => (arg1.order > arg2.order) ? 1 : -1);
    });
  }

  // Construit les champs du modèle dynamiquement
  private createFormFields(create = true): void {
    this.fieldsConfig = [];
    let validations = [];

    if (create) {
      this.fieldsModel = this.fieldsModel.filter(field => field !== 'translationId');
    } else {
      if (!this.fieldsModel.includes('translationId')) {
        this.fieldsModel.push('translationId');
      }
    }

    this.fieldsModel.forEach(field => {
      const inputType = this.hiddenFields.includes(field) ? 'hidden' : (field === 'order') ? 'number' : 'text';

      validations = [this.utilHelper.getValidator('required')];
      if (field === 'code') {
        validations.push(this.utilHelper.getValidator('maxlength'));
      }
      if (field === 'order') {
        validations.push(this.utilHelper.getValidator('min'));
      }

      this.fieldsConfig.push(
        {
          type: 'input',
          inputType,
          label: this.translations[field] ?? `*${field}`,
          name: field,
          validations: this.hiddenFields.includes(field) ? [] : validations,
          condition: null
        }
      );
    });
  }
}
