import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class TranslationHelper {
  constructor(
    private readonly translateService: TranslateService
  ) { }

  public initTranslation(language: string): void {
    // Initialisation de la langue d'utilisateur actuel
    const codeLanguage = language.split('-')[0] ?? 'fr';
    this.translateService.setDefaultLang(codeLanguage);
    this.translateService.use(codeLanguage);
  }
}
