import { KeyValue } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CandidatureService, ConfigurationService } from 'common-services';
import { CustomSnackBarService } from 'custom-snack-bar';
import { Subscription } from 'rxjs';
import { FieldConfigModel } from 'src/app/shared/models/field.model';

@Component({
  selector: 'app-cnil-excluded-origin',
  templateUrl: './cnil-excluded-origin.component.html',
  styleUrls: ['./cnil-excluded-origin.component.scss']
})
export class CnilExcludedOriginComponent implements OnInit, OnDestroy {
  public origins: Array<KeyValue<number, string>>;
  public cnilExcludedOrigins: Array<number>;
  public fieldsConfig: FieldConfigModel[] = [];

  private translations: Record<string, string>;
  private readonly subscription: Subscription;

  constructor(
    private readonly candidatureService: CandidatureService,
    private readonly configurationService: ConfigurationService,
    private readonly translateService: TranslateService,
    private readonly customSnackBarService: CustomSnackBarService
  ) {
    this.subscription = new Subscription();
  }

  ngOnInit(): void {
    const translateSubscription = this.translateService.get(
      [
        'CnilOrigins',
        'updateSuccess'
      ]
    ).subscribe(translation => {
      this.translations = translation;
    });
    this.subscription.add(translateSubscription);
    this.getOrigins();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public onSave($event: any): void {
    const cnilOriginIdsToExclude: Array<number> = [...$event['cnil-origin-excluded']];
    this.configurationService.getCnilExcludedOrigins().then(
      origins => {
        if (origins.length > 0) {
          origins.forEach(origin => {
            // On vérifie si l'origine est déjà exclue
            if (cnilOriginIdsToExclude.includes(origin.id)) {
              cnilOriginIdsToExclude.splice(cnilOriginIdsToExclude.indexOf(origin.id), 1);
            } else {
              // On supprime l'origine non exclue
              this.configurationService.DeleteCnilExcludedOrigin(origin.id);
            }
          });
        }

        // Ajout des origines à exclures
        cnilOriginIdsToExclude.forEach(originId => {
          this.configurationService.AddCnilExcludedOrigin(originId);
        });
      }
    );

    // On raffraichit la liste des origines exclues
    this.configurationService.getCnilExcludedOrigins().then(
      origins => {
        this.cnilExcludedOrigins = origins.map(origin => origin.id);
      }
    );

    this.customSnackBarService.showSuccess(this.translations.updateSuccess);
  }

  private getOrigins(): void {
    this.candidatureService.getOrigins().then(
      response => {
        this.origins = response.map(origin => ({ key: origin.id, value: origin.label }));
        this.configurationService.getCnilExcludedOrigins().then(
          res => {
            this.cnilExcludedOrigins = res.map(origin => origin.id);
            this.fieldsConfig.push({
              label: this.translations.CnilOrigins,
              name: 'cnil-origin-excluded',
              type: 'multipleCheckbox',
              options: this.origins,
              condition: null,
              validations: [],
              value: this.cnilExcludedOrigins
            } as FieldConfigModel);
          }
        );
      }
    );
  }
}
