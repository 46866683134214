import { Component, ViewEncapsulation } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FieldConfigModel } from 'src/app/shared/models/field.model';

@Component({
  selector: 'app-switcher',
  templateUrl: './switcher.component.html',
  styleUrls: ['./switcher.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SwitcherComponent {
  public fieldConfig: FieldConfigModel;
  public formGroup: FormGroup;
}
