import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import {
  AgencyModel,
  CivilityModel,
  CivilityService,
  DataDepthLevel, IndividualAgencyModel, IndividualModel, OrganizationService,
  QueryableModel,
  SortOrder
} from 'common-services';
import { Subscription } from 'rxjs';
import { ConfirmDialogComponent } from 'src/app/generic-components/confirm-dialog/confirm-dialog.component';
import { FormDialogComponent } from 'src/app/generic-components/form-dialog/form-dialog.component';
import { Dialogs } from 'src/app/shared/enums/dialogs-enum';
import { UtilHelper } from 'src/app/shared/helpers/util.helper';
import { DialogsManager } from 'src/app/shared/managers/dialog.manager';
import { FieldConfigModel } from 'src/app/shared/models/field.model';
import { pageSize } from 'src/globals';

@Component({
  selector: 'app-individual-agency-table',
  templateUrl: './individual-agency-table.component.html',
})
export class IndividualAgencyTableComponent implements OnInit, OnDestroy, AfterViewInit {

  @Input() agency: AgencyModel;

  @ViewChild(MatPaginator) paginator: MatPaginator;

  public dataSource: MatTableDataSource<IndividualAgencyModel>;
  public users: Array<Record<string, unknown>>;
  public displayedColumns = ['civility', 'firstName', 'lastName', 'principalAgency'];;
  public pageSize = 8;
  public civilities: Array<CivilityModel> = [];
  public title: string;
  public rowCount: number;

  private readonly subscription = new Subscription();;
  private translations: Record<string, string>;
  private individuals: IndividualAgencyModel[];
  private individualsToAdd: IndividualModel[];
  private search = '';
  private sortOrder = SortOrder.Ascending.toString();
  private currentPage = 0;
  private sortExpression = 'individual.firstName';
  private fieldsConfig: FieldConfigModel[];

  constructor(
    private readonly organizationService: OrganizationService,
    private readonly civilityService: CivilityService,
    private readonly dialogManager: DialogsManager,
    private readonly translateService: TranslateService,
    private readonly utilHelper: UtilHelper
  ) {
    this.dataSource = new MatTableDataSource<IndividualAgencyModel>();
  }

  public ngOnInit(): void {
    this.civilityService.getCivilities().then(result => this.civilities = result);

    this.getUsers();
    this.getUsersOfAgency();

    const translateSubscription = this.translateService.get(
      [
        'delete',
        'deleteUserConfirmationTitle',
        'deleteUserConfirmationMessage',
        'agencyUsers',
        'addUser'
      ]
    ).subscribe(translation => {
      this.translations = translation;
      this.title = this.translations.agencyUsers;
    });
    this.subscription.add(translateSubscription);
  }

  public ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public onCreate(): void {
    this.buildFormFields();
    const data = {
      title: `${this.translations.addUser} ${this.agency.label}`,
      fieldsConfig: this.fieldsConfig
    };

    const formDialogComponent = this.dialogManager.openDialog(FormDialogComponent, Dialogs.formDialog, data).componentInstance;
    formDialogComponent.save.subscribe((agency: any) => {
      // On crée la nouvelle agence
      const agencyModel = {
        agencyId: this.agency.id,
        individualId: agency.individualId,
        default: false
      } as IndividualAgencyModel;

      this.organizationService.addIndividualToAgency(this.agency.id, agency.individualId, agencyModel)
        .then(() => {
          this.getUsers();
          this.getUsersOfAgency();
          formDialogComponent.dialogRef.close();
          this.utilHelper.showNotification('addSuccess');
        }).catch(() => {
          this.utilHelper.showNotification('error', false);
        });
    });
  }

  public getCivility(id: number): string {
    return this.civilities.find(el => el.id === id)?.label;
  }

  public onDelete(individualAgency: IndividualModel): void {
    // Confirm dialog
    const name = `${individualAgency?.firstName} ${individualAgency?.lastName}`;
    const data = {
      title: `${this.translations.deleteUserConfirmationTitle} ${this.agency.label}`,
      message: `${this.translations.deleteUserConfirmationMessage} ${name} ?`,
      confirmButtonText: this.translations.delete
    };

    const confirmDialogComponent = this.dialogManager.openDialog(ConfirmDialogComponent, Dialogs.confirmDialog, data);
    confirmDialogComponent.afterClosed().subscribe(result => {
      if (result) {
        const individual = this.individuals.find(element => element.individual.id === individualAgency.id);
        if (individual?.individualId) {
          this.deleteIndividual(individual.individualId, this.agency.id);
        }
      }
    });
  }

  public onSearch(query: string): void {
    this.search = query;
    this.currentPage = 0;
    this.getUsers();
  }

  public onPaginate(page: number): void {
    this.currentPage = page - 1;
    this.getUsers();
  }

  private getUsers(): void {
    let whereExpression = `a => a.agencyId = ${this.agency.id}`;
    whereExpression += ` AND ( a.individual.lastName.contains("${this.search}") OR a.individual.firstName.contains("${this.search}") )`;

    const query: QueryableModel = {
      whereExpression,
      dataDepthLevel: DataDepthLevel.WithSubObjects,
      sortExpression: this.sortExpression,
      sortOrder: this.sortOrder,
      page: this.currentPage,
      pageSize
    };

    this.organizationService.findIndividualAgenciesByQueryable(query)
      .then(individualAgencies => {
        this.individuals = individualAgencies.items;
        this.users = [];
        this.rowCount = individualAgencies.rowCount;

        this.individuals.forEach(item => {
          const individual = item.individual as any;
          individual.principalAgency = item.default ? '✔' : 'X';
          individual.undeleted = item.default;
          individual.civility = this.getCivility(individual.civilityId);
          this.users.push(individual);
        });
      })
      .catch(() => this.utilHelper.showNotification('error', false));
  }

  private deleteIndividual(individualId: number, agencyId: number): void {
    this.organizationService.deleteIndividualFromAgency(individualId, agencyId)
      .then(() => {
        this.utilHelper.showNotification('deleteWithSuccess');
        this.getUsers();
      }).catch(() => {
        this.utilHelper.showNotification('error', false);
      });
  }

  private getUsersOfAgency() {
    const query: QueryableModel = {
      whereExpression: `p => p.agencyId = ${this.agency.id}`,
      dataDepthLevel: DataDepthLevel.Flat,
      page: 0,
      pageSize: 1000
    };

    // grouper les utilisateurs actuel de l'agence
    this.organizationService.findIndividualAgenciesByQueryable(query)
      .then(result => {
        // grouper les Ids des utilisateurs affecté à l'agence
        const individualIds = result.items?.map(el => el.individualId);
        this.getUsersToAdd(individualIds);
      })
      .catch(() => this.utilHelper.showNotification('error', false));
  }

  private getUsersToAdd(individualIds: Array<number>) {
    this.individualsToAdd = [];
    // Obtenir la liste des utilisteurs non affectés à l'agence
    const whereExpression = individualIds.length > 0 ? `p => !(p.id in (${individualIds.join(',')}))` : 'p => true';
    const query: QueryableModel = {
      whereExpression,
      dataDepthLevel: DataDepthLevel.Flat,
      sortExpression: 'firstName',
      sortOrder: SortOrder.Ascending.toString(),
      page: 0,
      pageSize: 1000
    };

    this.organizationService.findIndividualsByQueryable(query)
      .then(
        result => {
          result?.items.map(el => (el?.firstName || el?.lastName) ? this.individualsToAdd.push(el) : null);
        }
      )
      .catch(() => this.utilHelper.showNotification('error', false));
  }

  private buildFormFields() {
    this.fieldsConfig = [];

    const fieldConfig = {
      name: 'individualId',
      label: 'individualId',
      type: 'autocomplete',
      inputType: 'text',
      validations: [this.utilHelper.getValidator('required')],
      options: this.individualsToAdd.map(indv => ({ key: indv.id, value: ([indv?.firstName, indv?.lastName].join(' ')) })),
      condition: null
    } as FieldConfigModel;

    this.fieldsConfig.push(fieldConfig);
  }
}
