import { NgModule } from '@angular/core';
import { AuthGuardHelper } from './helpers/auth-guard.helper';
import { DialogHelper } from './helpers/dialog.helper';
import { TranslationHelper } from './helpers/translation.helper';
import { UtilHelper } from './helpers/util.helper';
import { ApplicativeAreaParameterService } from './services/applicative-area-parameter.service';

// Ajoutez vos helpers ici
const helpers = [
  AuthGuardHelper,
  DialogHelper,
  UtilHelper,
  TranslationHelper
];

// Ajoutez vos modules ici
const modules = [];

// Ajoutez vos services ici
const services = [
  ApplicativeAreaParameterService
];

@NgModule({
  providers: [
    ...helpers,
    ...services
  ],
  imports: [
    ...modules
  ],
  exports: [
    ...modules
  ]
})
export class SharedModule { }
