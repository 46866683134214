import { Component, OnDestroy, OnInit } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { TranslateService } from '@ngx-translate/core';
import {
  DataDepthLevel,
  IndividualModel,
  OrganizationService,
  QueryableModel,
  SortOrder
} from 'common-services';
import { Subscription } from 'rxjs';
import { UtilHelper } from 'src/app/shared/helpers/util.helper';
import { pageSize } from 'src/globals';

@Component({
  selector: 'app-users-list',
  templateUrl: './users-list.component.html'
})
export class UsersListComponent implements OnInit, OnDestroy {
  public readonly displayedColumns = ['civility', 'firstName', 'lastName', 'mailAddress', 'language', 'licenses'];
  public readonly sortByColumns = ['civility', 'firstName', 'lastName', 'mailAddress', 'language'];
  public status = 'listing';
  public selectedUser: IndividualModel;
  public users: Array<Record<string, unknown>>;
  public title: string;
  public rowCount: number;
  public search = '';
  public currentPage = 0;
  public sortColumn: Sort;

  private readonly subscription = new Subscription();
  private sortExpression = 'lastName';
  private sortOrder = SortOrder.Ascending.toString();

  constructor(
    private readonly organizationService: OrganizationService,
    private readonly utilHelper: UtilHelper,
    private readonly translateService: TranslateService
  ) { }

  ngOnInit(): void {
    this.subscription.add(
      this.translateService.get('users').subscribe(
        trad => this.title = trad
      )
    );

    // Récupérer les utilisateurs
    this.getUsers();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  // Pagination des utilisateurs
  public onPaginate(page: number): void {
    this.currentPage = page - 1;
    this.getUsers();
  }

  // Créer un utilisateur
  public onCreate(): void {
    this.status = 'adding';
  }

  // Mise à jour d'utilisateur
  public onEdit(user: IndividualModel): void {
    this.status = 'editing';
    this.selectedUser = user;
  }

  // Après ajout d'un utilisateur, afficher et rafraîchir la liste des utilisateurs
  public onCreateUser(): void {
    this.status = 'listing';
    this.getUsers();
  }

  // Après modification d'un utilisateur, afficher et rafraîchir la liste des utilisateurs
  public onUpdateUser(): void {
    this.onCreateUser();
  }

  // Recherche par ( Nom, prenom et email )
  public onSearch(search: string): void {
    this.search = search;
    this.currentPage = 0;
    this.getUsers();
  }

  // Trier les utilisateurs
  public onSort(sort: Sort): void {
    this.sortColumn = sort;

    switch (sort.active) {
      case 'civility':
        this.sortExpression = 'civility.label';
        this.changeSortOrder(sort.direction);
        break;

      case 'language':
        this.sortExpression = 'languageBU.label';
        this.changeSortOrder(sort.direction);
        break;

      default:
        this.sortExpression = sort.active;
        break;

    }

    if (!sort.direction) {
      this.sortExpression = 'lastName';
      this.sortOrder = SortOrder.Ascending.toString();
    } else if (this.sortExpression === sort.active) {
      this.changeSortOrder(sort.direction);
    }

    this.getUsers();
  }

  // Changer l'ordre des utilisateur
  private changeSortOrder(direction = ''): void {
    this.sortOrder = (direction === 'desc') ? SortOrder.Descending.toString() : SortOrder.Ascending.toString();
  }

  private async getUsers(): Promise<void> {
    // Récupérer les utilisateurs
    let whereExpression = 'i => i.userGuid != null ';
    whereExpression += 'AND ( i.firstName != "" OR i.lastName != "" ) ';
    whereExpression += 'AND ( i.firstName != null OR i.lastName != null ) ';
    // Recherche par nom, prenom et email
    whereExpression += `AND ( i.lastName.contains("${this.search}") `;
    whereExpression += `OR i.firstName.contains("${this.search}") `;
    whereExpression += `OR i.mailAddress.contains("${this.search}") ) `;

    const query: QueryableModel = {
      whereExpression,
      page: this.currentPage,
      sortExpression: this.sortExpression,
      sortOrder: this.sortOrder,
      dataDepthLevel: DataDepthLevel.WithSubObjectsAndSubLists,
      pageSize
    };

    await this.organizationService.findIndividualsByQueryable(query)
      .then(results => {
        this.users = [];
        this.rowCount = results.rowCount;
        results.items?.map(user => {
          const individual = user as any;
          individual.civility = user.civility?.label ?? '';
          individual.language = user.languageBU?.label ?? '';
          individual.licenses = '';

          this.users.push(individual);
        });
      })
      .catch(() => {
        this.utilHelper.showNotification('error', false);
      });
  }
}
