import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AgenciesListComponent } from './pages/agencies/agencies-list/agencies-list.component';
import { ApplicativeAreaParameterComponent } from './pages/applicative-area-parameter/applicative-area-parameter.component';
import { CivilityComponent } from './pages/civility/civility.component';
import { CnilActionTypeComponent } from './pages/cnil-action-type/cnil-action-type.component';
import { CnilExcludedOriginComponent } from './pages/cnil-excluded-origin/cnil-excluded-origin.component';
import { CustomWebSearchComponent } from './pages/custom-web-search/custom-web-search.component';
import { EntitiesComponent } from './pages/dynamic-fields/configurations/entities/entities.component';
import { DynamicFieldListComponent } from './pages/dynamic-fields/dynamic-fields-list/dynamic-fields-list.component';
import { HomeComponent } from './pages/home/home.component';
import { LoginComponent } from './pages/login/login.component';
import { NotFoundPageComponent } from './pages/not-found-page/not-found-page.component';
import { UsersListComponent } from './pages/users/users-list/users-list.component';
import { AuthGuardHelper } from './shared/helpers/auth-guard.helper';

// Ajoutez vos routes ici
const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'applicativeAreaParameters/:applicativeAreaCode',
    component: ApplicativeAreaParameterComponent,
    canActivate: [AuthGuardHelper]
  },
  {
    path: 'civility',
    component: CivilityComponent,
    canActivate: [AuthGuardHelper]
  },
  {
    path: 'customWebSearch',
    component: CustomWebSearchComponent,
    canActivate: [AuthGuardHelper]
  },
  {
    path: 'cnilExcludedOrigin',
    component: CnilExcludedOriginComponent,
    canActivate: [AuthGuardHelper]
  },
  {
    path: 'cnilActionType',
    component: CnilActionTypeComponent,
    canActivate: [AuthGuardHelper]
  },
  {
    path: 'dynamicFields',
    component: DynamicFieldListComponent,
    canActivate: [AuthGuardHelper]
  },
  {
    path: 'dynamicFieldForms',
    component: EntitiesComponent,
    canActivate: [AuthGuardHelper]
  },
  {
    path: 'agencies',
    component: AgenciesListComponent,
    canActivate: [AuthGuardHelper]
  },
  {
    path: 'users',
    component: UsersListComponent,
    canActivate: [AuthGuardHelper]
  },
  {
    path: '',
    component: HomeComponent,
    canActivate: [AuthGuardHelper]
  },
  {
    path: '**',
    component: NotFoundPageComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
