import { Component, OnDestroy, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CustomSnackBarService } from 'custom-snack-bar';
import { Subscription } from 'rxjs';
import {
  ApplicativeAreaParameterModel,
  ApplicativeAreaParameterService,
  ConditionModel,
  ParameterPropertyDescriptorModel,
  ParameterPropertyType
} from 'common-services';
import { FieldConfigModel } from 'src/app/shared/models/field.model';
import { FieldsetConfigModel } from 'src/app/shared/models/fielset-config.model';

@Component({
  selector: 'app-applicative-area-parameter',
  templateUrl: './applicative-area-parameter.component.html'
})
export class ApplicativeAreaParameterComponent implements OnInit, OnDestroy {
  public fieldsConfig: FieldConfigModel[] = [];
  public fieldsetConfig: FieldsetConfigModel[] = [];
  public isLoaded = false;
  public currentApplicativeArea: number;

  private translations: Record<string, string>;
  private currentApplicativeAreaParameter: ApplicativeAreaParameterModel;
  private readonly subscription: Subscription;

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly applicativeAreaParameterService: ApplicativeAreaParameterService,
    private readonly translateService: TranslateService,
    private readonly customSnackBarService: CustomSnackBarService
  ) {
    this.subscription = new Subscription();
  }

  ngOnInit(): void {
    const translateSubscription = this.translateService.get(
      [
        'updateSuccess',
        'error',
        'automaticDeletionOfCandidateData',
        'informationEmailToTheCandidateFollowingTheDeletionOfHisData',
        'sending1stEmailToCandidateFollowingDataDeletion',
        'sending2stInformationEmailToTheCandidateFollowingTheDeletionOfHisData',
        'requiredField'
      ]
    ).subscribe(translation => {
      this.translations = translation;
    });
    this.subscription.add(translateSubscription);
    this.activatedRoute.params.subscribe((params) => {
      this.isLoaded = false;
      // Recuperer l'applicativeArea code
      this.currentApplicativeArea = parseInt(params.applicativeAreaCode, 10);
      // Recuperer la configuration des fieldsets pour le formulaire
      this.getFieldSetsConfig(this.currentApplicativeArea).then((result) => {
        this.fieldsetConfig = result;
      });
      // Récupérer l'applicativeArea parameters par l'applicativeArea code
      this.applicativeAreaParameterService
        .getApplicativeAreaParametersByApplicativeAreaCode(
          this.currentApplicativeArea
        )
        .then((response) => {
          this.currentApplicativeAreaParameter = response;
          this.fieldsConfig = this.sortFields(
            this.currentApplicativeArea,
            this.getFieldsConfig(response.parameterPropertyDescriptors)
          );

          this.isLoaded = true;
        });
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public onUpdate(data: any): void {
    const stringfy = ['object', 'string'];
    // Recuperer les valeurs du parameterPropertyDescriptors
    this.currentApplicativeAreaParameter.parameterPropertyDescriptors.forEach(
      (element, index) => {
        if (element.type === ParameterPropertyType.parameterModel) {
          element.parameterPropertyDescriptors.forEach((param, paramIndex) => {
            const value = stringfy.includes(typeof data[param.name])
              ? JSON.stringify(data[param.name])
              : data[param.name];

            this.currentApplicativeAreaParameter.parameterPropertyDescriptors[
              index
            ].parameterPropertyDescriptors[
              paramIndex
            ].value = value;
          });
        } else {
          const value = stringfy.includes(typeof data[element.name])
            ? JSON.stringify(data[element.name])
            : data[element.name];
          this.currentApplicativeAreaParameter.parameterPropertyDescriptors[
            index
          ].value = value;
        }
      }
    );

    // Modifier les parametres
    this.applicativeAreaParameterService
      .updateApplicativeArea(this.currentApplicativeAreaParameter)
      .then(() => {
        // Afficher la notification du modification avec succès
        this.customSnackBarService.showSuccess(this.translations.updateSuccess);
      })
      .catch(() => {
        // Afficher la notification dans le cas d'erreur
        this.customSnackBarService.showDanger(this.translations.error);
      });
  }


  public async getFieldSetsConfig(configId: number): Promise<FieldsetConfigModel[]> {
    switch (configId) {
      case 13:
        return new Promise((resolve) => {
          resolve([
            {
              start: 'ConfigCnil',
              end: 'MailAdressAdminCnil',
              title: this.translations.automaticDeletionOfCandidateData,
            },
            {
              start: 'ModelLastMailCNILId',
              end: 'ModelLastMailCNILId',
              title: this.translations.informationEmailToTheCandidateFollowingTheDeletionOfHisData,
            },
            {
              start: 'SendMailToRecruitedCandidate',
              end: 'ModelFirstMailCNILId',
              title: this.translations.sending1stEmailToCandidateFollowingDataDeletion,
            },
            {
              start: 'DelayBeforeSecondMailInDays',
              end: 'ModelSecondMailCNILId',
              title: this.translations.sending2stInformationEmailToTheCandidateFollowingTheDeletionOfHisData,
            },
            {
              start: 'DeleteRecruitedCandidate',
              end: 'InformationMailType',
            },
          ]);
        });

      default:
        return;
    }
  }

  // Regrouper les champs du formulaire selon un ordre définit dans 'fieldsOrder'
  public sortFields(configId: number, fieldsConfig: FieldConfigModel[]): FieldConfigModel[] {
    const result = [];
    this.getFieldsOrder(configId).forEach((name) => {
      const element = fieldsConfig.find((field) => field.name === name);
      if (element) {
        result.push(element);
      }
    });

    return [...new Set([...result, ...fieldsConfig])];
  }

  private getFieldsConfig(
    parameters: ParameterPropertyDescriptorModel[],
    condition: ConditionModel = null
  ): FieldConfigModel[] {
    let formFields = [];
    // Créer le formulaire
    parameters.forEach((element) => {
      // Champ type objet
      if (element.type === ParameterPropertyType.parameterModel) {
        formFields = [
          ...formFields,
          ...this.getFieldsConfig(
            element.parameterPropertyDescriptors,
            element.condition
          ),
        ];
      } else {
        // Par défaut le champ de type text
        const config: FieldConfigModel = {
          type: 'input',
          inputType: 'text',
          label: element.label,
          name: element.name,
          value: element.value !== 'null' ? JSON.parse(element.value) : '',
          validations: [],
          condition: condition ?? element.condition
        };

        switch (element.type) {
          // Champ type nombre
          case ParameterPropertyType.int:
          case ParameterPropertyType.double:
          case ParameterPropertyType.long:
            config.inputType = 'number';
            break;

          // Champ de type date
          case ParameterPropertyType.dateTime:
            config.type = 'dateTime';
            break;

          // Pour champ de type switcher
          case ParameterPropertyType.bool:
            config.type = 'switcher';
            config.inputType = 'switcher';
            config.value = element.value === 'true';
            break;

          // Champ multiple select
          case ParameterPropertyType.enum:
            config.type = element.multipleValue ? 'multipleCheckbox' : 'select';
            config.value = JSON.parse(element.value);
            config.options = element.predefinedValues;
            break;

          default:
            break;
        }

        // Validations
        if (element.required) {
          config.validations.push({
            name: 'required',
            validator: Validators.required,
            message: this.translations.requiredField
          });
        }

        formFields.push(config);
      }
    });

    return formFields;
  }

  private getFieldsOrder(configId: number): string[] {
    switch (configId) {
      case 13:
        return [
          'ConfigCnil',
          'RetentionTime',
          'MailAdressSenderCnil',
          'MailAdressAdminCnil',
          'ModelLastMailCNILId',
          'SendMailToRecruitedCandidate',
          'DelayBeforeFirstMailInDays',
          'ModelFirstMailCNILId',
          'DelayBeforeSecondMailInDays',
          'ModelSecondMailCNILId',
          'DeleteRecruitedCandidate',
          'InformationMailType',
        ];

      default:
        return [];
    }
  }
}
