import { Component, EventEmitter, Inject, Output, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormDialogModel } from 'src/app/shared/models/form-dialog.model';

@Component({
  selector: 'app-form-dialog',
  templateUrl: './form-dialog.component.html',
  styleUrls: ['./form-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FormDialogComponent<T> {
  @Output() save: EventEmitter<T> = new EventEmitter<T>();

  constructor(
    public dialogRef: MatDialogRef<FormDialogComponent<T>>,
    @Inject(MAT_DIALOG_DATA) public data: FormDialogModel
  ) { }

  onSave(item: T): void {
    this.save.emit(item);
  }

  public close(): void {
    this.dialogRef.close();
  }
}
