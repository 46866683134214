import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { AuthenticationsService } from 'common-services';
import { Observable } from 'rxjs';

@Injectable()
export class AuthGuardHelper implements CanActivate {

  constructor(
    private readonly router: Router,
    private readonly authenticationsService: AuthenticationsService
  ) { }

  // Vérifier si l'utilisateur est connecté
  canActivate(): Observable<boolean> | Promise<boolean> | boolean | UrlTree {
    return this.authenticationsService.isLoggedIn() ? true : this.router.parseUrl('/login');
  }

}
