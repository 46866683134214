import { Component, OnInit } from '@angular/core';
import { DataDepthLevel, IndividualModel, UserService } from 'common-services';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html'
})
export class HomeComponent implements OnInit {

  public user: IndividualModel;
  constructor(
    private readonly userService: UserService
  ) { }

  ngOnInit(): void {
    this.user = JSON.parse(localStorage.getItem('individual'));

    this.userService.getUserByGuid(this.user.userGuid, DataDepthLevel.Flat.toString())
      .then(result => {
        localStorage.setItem('tenantGuid', result.tenantGuid);
      });
  }
}
