import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { pageSize } from 'src/globals';

@Component({
  selector: 'app-editable-table',
  templateUrl: './editable-table.component.html',
  styleUrls: ['./editable-table.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EditableTableComponent<T> implements OnInit, AfterViewInit, OnChanges {

  @Input() displayedColumns: string[];
  @Input() data: T[];
  @Input() title = '';
  @Input() canDelete = true; // Input utilisable pour afficher ou cacher le bouton de suppression des colonnes
  @Input() canUpdate = true; // Input utilisable pour afficher ou cacher le bouton de la modification des colonnes
  // Inputs pour les traduction
  @Input() translatableDomainModelName?: string;
  @Input() hasTranslation: boolean; // Variable pour afficher les traductions
  // Inputs pour la recherche
  @Input() searchable = false;
  @Input() search = '';
  // Inputs pour le tri
  @Input() sortByColumns: string[] = [];
  @Input() sortColumn: Sort; // Colonne utilisé pour trier le tableau
  // Inputs pour la pagination
  @Input() hasPagination: boolean; // Variables pour afficher ou cacher la pagination
  @Input() rowCount: number; // Nombre  total des éléments récupérés depuis L'API
  @Input() pageSize = pageSize;
  @Input() pageIndex = 0;

  @Output() edit: EventEmitter<T> = new EventEmitter<T>();
  @Output() create: EventEmitter<T> = new EventEmitter<T>();
  @Output() delete: EventEmitter<T> = new EventEmitter<T>();
  @Output() filter: EventEmitter<string> = new EventEmitter<string>();
  @Output() paginate: EventEmitter<number> = new EventEmitter<number>();
  @Output() sort: EventEmitter<Sort> = new EventEmitter<Sort>();

  @ViewChild(MatPaginator) paginator: MatPaginator;

  public dataSource: MatTableDataSource<T>;
  public focused = false;

  constructor(
    private cdRef: ChangeDetectorRef
  ) {
    this.dataSource = new MatTableDataSource<T>();
  }

  public ngOnInit(): void {
    this.dataSource.data = this.data ?? [];
    this.displayedColumns = [...this.displayedColumns, 'actions'];
  }

  public ngAfterViewInit(): void {
    this.initPageIndex(this.pageIndex);
    this.cdRef.detectChanges();
  }

  // Trier un tableau
  public sortData(sort: Sort): void {
    this.sort.emit(sort);
  }

  // pagination depuis ( Mat Paginator )
  public onChangePage(event: PageEvent): void {
    const page = event.pageIndex + 1;
    this.paginate.emit(page);
  }

  public ngOnChanges(): void {
    this.dataSource.data = this.data ?? [];
  }

  public onSearch(): void {
    this.initPageIndex();
    this.filter.emit(this.search);
  }

  public clearSearch(): void {
    this.search = '';
    this.initPageIndex();
    this.filter.emit(this.search);
  }

  public onFocus(): void {
    this.focused = true;
  }

  public onBlur(): void {
    this.focused = false;
  }

  public onEdit(item: T): void {
    this.edit.emit(item);
  }

  public onCreate(): void {
    this.create.emit();
  }

  public onDelete(item: T): void {
    this.delete.emit(item);
  }

  private initPageIndex(pageIndex = 0): void {
    if (this.paginator) {
      this.paginator.pageIndex = pageIndex;
    }
  }
}
