import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { LanguageModel, LanguageService, TranslationModel, TranslationService } from 'common-services';
import { Subscription } from 'rxjs';
import { Dialogs } from 'src/app/shared/enums/dialogs-enum';
import { UtilHelper } from 'src/app/shared/helpers/util.helper';
import { DialogsManager } from 'src/app/shared/managers/dialog.manager';
import { FieldConfigModel } from 'src/app/shared/models/field.model';
import { FormDialogModel } from 'src/app/shared/models/form-dialog.model';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { FormDialogComponent } from '../form-dialog/form-dialog.component';

@Component({
  selector: 'app-translate',
  templateUrl: './translate.component.html',
  styleUrls: ['./translate.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TranslateComponent<T> implements OnInit, OnDestroy {
  @Input() modelToTranslate: T; // Définit le modéle a traduire
  @Input() translatableDomainModelName: string; // Définit le nom générique du modéle a traduire (Ex: CivilityModel)
  @Input() showLanguages = true; // Définit si on affiche les codes langues des traductions déjà insérées
  @Output() update: EventEmitter<TranslationModel> = new EventEmitter<TranslationModel>();
  @Output() delete: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild('template') template: TemplateRef<any>;

  public dataSource = new MatTableDataSource<TranslationModel>();
  public displayedColumns: string[];
  public codeLanguages: string[] = [];
  public addTranslate: boolean;
  public isEditMode: boolean;
  public elementToEdit: string;

  private translations: Record<string, string>;
  private fieldsConfig: FieldConfigModel[];
  private fieldsModel: string[];
  private hiddenFields: string[];
  private languages: LanguageModel[] = [];
  // Propriétés du modèle générique
  private translationId = 'translationId';
  private id = 'id';
  private readonly subscription: Subscription;

  constructor(
    private readonly dialogManager: DialogsManager,
    private readonly dialog: MatDialog,
    private readonly languageService: LanguageService,
    private readonly translationService: TranslationService,
    private readonly translateService: TranslateService,
    private readonly utilHelper: UtilHelper
  ) {
    this.displayedColumns = ['Language', 'TranslationValue', 'Actions'];
    this.hiddenFields = ['guid'];
    this.fieldsModel = ['guid', 'translationValue', 'languageId'];
    this.isEditMode = false;
    this.subscription = new Subscription();
  }


  public ngOnInit(): void {
    const translateSubscription = this.translateService.get(
      [
        'new',
        'deleteConfirmationTitle',
        'deleteConfirmationMessage',
        'delete'
      ]
    ).subscribe(translation => {
      this.translations = translation;
    });
    this.subscription.add(translateSubscription);
    this.getApplicationLanguages();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  // Ouvre la popup
  public openModal(): void {
    this.dialogManager.openDialog(this.template, Dialogs.translateDialog);
  }

  // Ferme la popup
  public closeModal(): void {
    this.dialog.closeAll();
    this.isEditMode = false;
  }

  // Crée une nouvelle traduction
  public onCreate(): void {
    this.createFormFields();
    // Les données du popup pour afficher le formulaire
    const data = {
      title: this.translations.new,
      fieldsConfig: this.fieldsConfig
    } as FormDialogModel;

    // Ouvre la popup d'ajout
    const formDialogComponent = this.dialogManager.openDialog(FormDialogComponent, Dialogs.formDialog, data).componentInstance;
    formDialogComponent.save.subscribe((translationModel: TranslationModel) => {
      this.translationService.addTranslation(this.translatableDomainModelName, this.modelToTranslate[this.id], translationModel)
        .then((translation: TranslationModel) => {
          this.modelToTranslate[this.translationId] = translation.guid;
          this.getTranslations();
          formDialogComponent.dialogRef.close();
          this.utilHelper.showNotification('addSuccess');
        }).catch(() => {
          this.utilHelper.showNotification('error', false);
        });
    });
  }

  // Affiche l'edition inline
  public onEdit(elementToEdit: string): void {
    this.isEditMode = true;
    this.elementToEdit = elementToEdit;
  }

  // Annule l'edition inline
  public onCancel(): void {
    this.isEditMode = false;
    this.getTranslations();
  }

  // Sauvegarde les changements aprés la modification
  public onSaveUpdate(translationModel: TranslationModel): void {
    this.translationService.addTranslation(this.translatableDomainModelName, this.modelToTranslate[this.id], translationModel).then(() => {
      this.getTranslations();
      // On annule l'edition inline
      this.isEditMode = false;
      this.utilHelper.showNotification('updateSuccess');
    }).catch(() => {
      this.utilHelper.showNotification('error', false);
    });
  }

  // Supprime une traduction
  public onDelete(translation: TranslationModel): void {
    // Les données pour ConfirmDialog
    const data = {
      title: this.translations.deleteConfirmationTitle,
      message: this.translations.deleteConfirmationMessage,
      confirmButtonText: this.translations.delete
    };

    // Ouvrir le popup de confirmation
    const confirmDialogComponent = this.dialogManager.openDialog(ConfirmDialogComponent, Dialogs.confirmDialog, data);
    confirmDialogComponent.afterClosed().subscribe(result => {
      if (result) {
        // Supprimer l'élément aprés confirmation
        this.translationService.deleteTranslation(
          this.translatableDomainModelName,
          this.modelToTranslate[this.id],
          translation.languageId)
          .then(() => {
            this.getTranslations();
            this.utilHelper.showNotification('deleteWithSuccess');
          }).catch(() => {
            this.utilHelper.showNotification('error', false);
          });
      }
    });
  }

  // Récupèrer le drapeau du langue
  public flagUri(code: string): string {
    return `/assets/img/flags/${code}.png`;
  }

  // Récupère les traductions éventuelles du modèle à traduire
  private async getTranslations(): Promise<void> {
    return this.translationService.getTranslations(this.translatableDomainModelName, this.modelToTranslate[this.translationId]).then(
      translations => {
        this.dataSource.data = translations;
        this.codeLanguages = translations.map(translation => translation.languageCultureInfo.split('-')[0]);
        // Si toutes les traductions sont insérés dans les différentes langue de l'application on désactive le button d'ajout
        this.addTranslate = !(this.codeLanguages.length === this.languages.map(language => language.code).length);
      }
    );
  }

  // Récupère les langues d'application
  private async getApplicationLanguages(): Promise<void> {
    return this.languageService.getApplicationLanguages().then(
      languages => {
        this.languages = languages;
        this.getTranslations();
      }
    );
  }

  // Construit les champs du modèle dynamiquement
  private createFormFields(): void {
    this.fieldsConfig = [];

    // On définit les régles de validation des champs
    const validations = [this.utilHelper.getValidator('required')];

    // Configuration des champs du formulaire
    this.fieldsModel.forEach(field => {
      const value = (field === 'guid') ? this.modelToTranslate[this.translationId] : '';
      const inputType = this.hiddenFields.includes(field) ? 'hidden' : 'text';

      const fieldConfig = {
        name: field,
        label: field,
        value: `${value}`,
        validations,
        condition: null
      } as FieldConfigModel;

      if (field === 'languageId') {
        const languages = this.languages;
        const options = languages.filter((language) => !this.dataSource.data.map(ts => ts.languageCultureInfo).includes(language.code));
        fieldConfig.options = options.map(language => ({ key: language.id, value: language.label }));
        fieldConfig.type = 'select';
      } else {
        fieldConfig.type = 'input';
        fieldConfig.inputType = inputType;
      }

      this.fieldsConfig.push(fieldConfig);
    });
  }
}
