<h1 class="setting-title">
    {{ title }}

    <button class="btn-eolia btn-primary float-right" mat-stroked-button (click)="onCreate()">
        <mat-icon>add</mat-icon> {{ 'new' | translate }}
    </button>
</h1>

<div class="table-wrapper" id="editable-table">
    <div class="search-input" [ngClass]="{'focused': focused}" *ngIf="searchable">
        <div>
            <mat-form-field>
                <input (focus)="onFocus()" (blur)="onBlur()" (keyup)="onSearch()" autocomplete="off" matInput
                    placeholder="{{ 'search' | translate }}" [(ngModel)]="search">
                <mat-icon *ngIf="!search; else elseBlock;" matPrefix>search</mat-icon>
                <ng-template #elseBlock>
                    <mat-icon matPrefix (click)="clearSearch()">clear</mat-icon>
                </ng-template>
            </mat-form-field>
        </div>
        <br>
    </div>

    <table mat-table [dataSource]="dataSource" matSortActive="{{sortColumn?.active}}"
        matSortDirection="{{sortColumn?.direction}}" matSort (matSortChange)="sortData($event)">
        <caption></caption>

        <div *ngFor="let col of displayedColumns">
            <ng-container [matColumnDef]="col" *ngIf="col !== 'actions'; else elseblock">

                <ng-container *ngIf="sortByColumns.indexOf(col) === -1; else sortableColumn">
                    <th scope="col" mat-header-cell *matHeaderCellDef>
                        <span>{{col | translate}}</span>
                    </th>
                </ng-container>

                <ng-template #sortableColumn>
                    <th [mat-sort-header]="col" scope="col" mat-header-cell *matHeaderCellDef>
                        <span>{{col | translate}}</span>
                    </th>
                </ng-template>

                <td mat-cell *matCellDef="let element">
                    <span title="{{element[col]}}">
                        {{element[col]}}
                    </span>
                </td>
            </ng-container>
            <ng-template #elseblock>
                <ng-container [matColumnDef]="col">
                    <th scope="col" mat-header-cell *matHeaderCellDef>
                        <span>{{ 'actions' | translate }}</span>
                    </th>
                    <td mat-cell *matCellDef="let element">
                        <!-- actions-button -->
                        <div class="actions">
                            <!-- translate -->
                            <app-translate *ngIf="hasTranslation" [modelToTranslate]="element"
                                [translatableDomainModelName]="translatableDomainModelName"></app-translate>
                            <!-- /translate -->

                            <mat-icon class="icon-actions" [matMenuTriggerFor]="menu">more_horiz</mat-icon>

                            <mat-menu class="eolia-mat-menu" #menu="matMenu">
                                <!-- edit-button -->
                                <button [disabled]="!canUpdate" mat-menu-item class="action-wrapper gray"
                                    (click)="onEdit(element)">
                                    <mat-icon class="edit">edit</mat-icon>
                                    {{ 'modify' | translate }}
                                </button>
                                <!-- /edit-button -->

                                <!-- delete-button -->
                                <button [disabled]="!( canDelete && !element.undeleted )" mat-menu-item class="action-wrapper danger"
                                    (click)="onDelete(element)">
                                    <mat-icon class="remove" (click)="onDelete(element)">delete</mat-icon>
                                    {{ 'delete' | translate }}
                                </button>
                                <!-- /delete-button -->
                            </mat-menu>
                        </div>
                        <!-- /actions-button -->
                    </td>
                </ng-container>
            </ng-template>
        </div>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <div *ngIf="hasPagination && rowCount">
        <mat-paginator #paginator [length]="rowCount" [pageSize]="pageSize" (page)="onChangePage($event)"
            aria-label="Select page">
        </mat-paginator>
    </div>
</div>
