<div class="user-information">
    <form id="edit-user-form" [formGroup]="userForm">
        <div class="container">
            <div class="row">
                <div class="col-sm-12 col-md-6 col-lg-6">
                    <mat-form-field>
                        <label>
                            {{ 'userLogin' | translate}} <span class="required-field">*</span>
                        </label>
                        <input matInput [formControl]="userForm.controls.login">
                        <mat-error *ngIf="userForm.controls.login.hasError('required')">
                            {{ 'requiredField' | translate}}
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-6">
                    <mat-form-field>
                        <label>
                            {{ 'language' | translate}} <span class="required-field">*</span>
                        </label>
                        <mat-select [formControl]="userForm.controls.language">
                            <mat-option *ngFor="let lang of languages" [value]="lang.code">{{lang.label}}</mat-option>
                        </mat-select>
                        <mat-error *ngIf="userForm.controls.language.hasError('required')">
                            {{ 'requiredField' | translate}}
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-6">
                    <mat-form-field>
                        <label>
                            {{ 'countryCode' | translate}} <span class="required-field">*</span>
                        </label>
                        <mat-select [formControl]="userForm.controls.countryCode">
                            <mat-option *ngFor="let country of countries" [value]="country.code">{{country.code}}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="userForm.controls.countryCode.hasError('required')">
                            {{ 'requiredField' | translate}}
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12 col-md-6 col-lg-6">
                    <mat-form-field class="switcher-component mat-slide-content" appearance="none">
                        <label>
                            {{ 'active' | translate}}
                        </label>
                        <mat-slide-toggle color="primary" [formControl]="userForm.controls.active"
                            (change)="onActiveChange()">
                        </mat-slide-toggle>
                        <textarea matInput hidden></textarea>
                    </mat-form-field>
                </div>

                <div class="col-sm-12 col-md-6 col-lg-6">
                    <mat-form-field class="switcher-component mat-slide-content" appearance="none">
                        <label>
                            {{ 'portalManagerAccess' | translate}}
                        </label>
                        <mat-slide-toggle color="primary" [formControl]="userForm.controls.portalManagerAccess">
                        </mat-slide-toggle>
                        <textarea matInput hidden></textarea>
                    </mat-form-field>
                </div>

                <div class="col-sm-12 col-md-6 col-lg-6">
                    <mat-form-field class="switcher-component mat-slide-content" appearance="none">
                        <label>
                            {{ 'isTaskExecutor' | translate}}
                        </label>
                        <mat-slide-toggle color="primary" [formControl]="userForm.controls.isTaskExecutor">
                        </mat-slide-toggle>
                        <textarea matInput hidden></textarea>
                    </mat-form-field>
                </div>

                <div class="col-sm-12 col-md-6 col-lg-6">
                    <mat-form-field class="switcher-component mat-slide-content" appearance="none">
                        <label>
                            {{ 'isSystem' | translate}}
                        </label>
                        <mat-slide-toggle color="primary" [formControl]="userForm.controls.isSystem">
                        </mat-slide-toggle>
                        <textarea matInput hidden></textarea>
                    </mat-form-field>
                </div>
            </div>
            <button mat-raised-button class="btn-eolia btn-primary save-btn" (click)="editUser()"
                [disabled]="!userForm.valid || !userForm.dirty">
                <mat-icon>check</mat-icon>{{ 'save' | translate}}
            </button>
        </div>
    </form>
</div>
