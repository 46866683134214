<div class="user-agency">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <mat-form-field>
                    <label>{{ 'parentAgency' | translate}}</label>
                    <mat-select [formControl]="parentAgency" (selectionChange)="selectedParentAgency($event.value)">
                        <mat-option *ngFor="let agency of potentialParentAgencies" [value]="agency.id">{{agency.label}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="col-12" *ngIf="parentAgencyId">
                <div class="label">{{ 'managedAgencies' | translate }} 
                    <span *ngIf="managedAgencies.length > 0">({{managedAgencies.length}} {{'nbrAgencies' | translate}})</span>
                </div>
                <div class="managed-agencies">
                    <mat-form-field class="filter-margin">
                        <label>
                            {{ 'filterAgencies' | translate}}
                        </label>
                        <input matInput [formControl]="managedAgency">
                    </mat-form-field>
                    <div class=" scroll-wrapper">
                        <div class="scroll-managed-agencies scroll">
                            <div *ngIf="managedAgencies.length > 0">
                                <p>{{'alreadyManagedAgencies' | translate}}</p>
                                <div *ngFor="let agency of managedAgencies">
                                    <mat-checkbox [checked]="true" (change)="deleteUserFromAgency(agency.id)">
                                        {{agency.label}}
                                    </mat-checkbox>
                                </div>
                            </div>
                            <div *ngIf="(filteredOptions| async)?.length > 0">
                                <p>{{'addManagedAgency' | translate}}</p>
                                <div *ngFor="let agency of filteredOptions | async">
                                    <mat-checkbox [checked]="false" (change)="addManagedAgency(agency.id)">
                                        {{agency.label}}
                                    </mat-checkbox>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
