import { Inject, Injectable } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';
@Injectable()
export class TranslatedPaginator extends MatPaginatorIntl {

  private rangeLabel: string;

  constructor(@Inject(TranslateService) private readonly translateService: TranslateService) {
    super();
    this.initTranslations();
  }

  private initTranslations(): void {
    this.translateService.get(['nextPage', 'itemsPerPage', 'previousPage', 'firstPage', 'lastPage', 'of']).subscribe(res => {
      this.nextPageLabel = res.nextPage;
      this.itemsPerPageLabel = res.itemsPerPage;
      this.previousPageLabel = res.previousPage;
      this.firstPageLabel = res.firstPage;
      this.lastPageLabel = res.lastPage;
      this.rangeLabel = res.of;
    });

    this.getRangeLabel = (page: number, pageSize: number, length: number) => {
      if (length === 0 || pageSize === 0) { return `0 ${this.rangeLabel} ${length}`; }
      length = Math.max(length, 0);
      const startIndex = page * pageSize;
      const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
      return `${startIndex + 1} - ${endIndex} ${this.rangeLabel} ${length}`;
    };
  }
}
