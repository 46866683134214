<div [ngSwitch]="status">
    <div *ngSwitchCase="'listing'">
        <app-editable-table [sortColumn]="sortColumn" [pageIndex]="currentPage" [search]="search"
            [sortByColumns]="sortByColumns" [displayedColumns]="displayedColumns" [data]="users" [canDelete]="false"
            [hasTranslation]="false" [title]="title" [searchable]="true" [hasPagination]="true" [rowCount]="rowCount"
            (create)="onCreate()" (edit)="onEdit($event)" (filter)="onSearch($event)" (paginate)="onPaginate($event)"
            (sort)="onSort($event)">
        </app-editable-table>
    </div>
    <div *ngSwitchCase="'adding'">
        <app-add-user (createUser)="onCreateUser()"></app-add-user>
    </div>
    <div *ngSwitchCase="'editing'">
        <app-edit-user [userToEdit]="selectedUser" (updateUser)="onUpdateUser()"></app-edit-user>
    </div>
</div>
