<app-editable-table
    [displayedColumns]="displayedColumns"
    [data]="data"
    [searchable]="true"
    [hasTranslation]="false"
    [hasPagination]="true"
    [rowCount]="rowCount"
    [pageSize]="pageSize"
    (create)="onCreate()"
    (edit)="onEdit($event)"
    (delete)="onDelete($event)"
    (paginate)="onPaginate($event)"
    (filter)="onFilter($event)">
</app-editable-table>
