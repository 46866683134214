import { KeyValue } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ActionFollowUpService, ConfigurationService } from 'common-services';
import { CustomSnackBarService } from 'custom-snack-bar';
import { Subscription } from 'rxjs';
import { FieldConfigModel } from './../../shared/models/field.model';

@Component({
  selector: 'app-cnil-action-type',
  templateUrl: './cnil-action-type.component.html'
})
export class CnilActionTypeComponent implements OnInit, OnDestroy {
  public actionTypes: Array<KeyValue<number, string>>;
  public cnilIncludedActionTypes: Array<number>;
  public fieldsConfig: FieldConfigModel[] = [];

  private translations: Record<string, string>;
  private readonly subscription: Subscription;

  constructor(
    private readonly followUpService: ActionFollowUpService,
    private readonly configurationService: ConfigurationService,
    private readonly translateService: TranslateService,
    private readonly customSnackBarService: CustomSnackBarService
  ) {
    this.subscription = new Subscription();
  }

  ngOnInit(): void {
    const translateSubscription = this.translateService.get(
      [
        'CnilActionTypes',
        'updateSuccess'
      ]
    ).subscribe(translation => {
      this.translations = translation;
    });
    this.subscription.add(translateSubscription);
    this.getActionTypes();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public onSave($event: any): void {
    const cnilActionTypesIds: Array<number> = [...$event['cnil-action-types']];
    this.configurationService.getCnilActionTypes().then(
      actionTypes => {
        if (actionTypes.length > 0) {
          actionTypes.forEach(actionType => {
            // On vérifie si le type d'action est déjà inclue
            if (cnilActionTypesIds.includes(actionType.id)) {
              cnilActionTypesIds.splice(cnilActionTypesIds.indexOf(actionType.id), 1);
            } else {
              // On supprime le type d'action non inclue
              this.configurationService.DeleteCnilActionType(actionType.id);
            }
          });
        }

        // Ajout des types d'actions à inclure
        cnilActionTypesIds.forEach(actionTypeId => {
          this.configurationService.AddCnilActionType(actionTypeId);
        });
      }
    );

    // On raffraichit la liste des types d'actions
    this.configurationService.getCnilActionTypes().then(
      actionTypes => {
        this.cnilIncludedActionTypes = actionTypes.map(actionType => actionType.id);
      }
    );
    this.customSnackBarService.showSuccess(this.translations.updateSuccess);
  }

  private getActionTypes(): void {
    this.followUpService.getActionFollowUpTypes().then(
      response => {
        this.actionTypes = response.map(actionType => ({ key: actionType.id, value: actionType.label }));
        this.configurationService.getCnilActionTypes().then(
          res => {
            let fieldConfigLabel = '';
            this.cnilIncludedActionTypes = res.map(actionType => actionType.id);
            fieldConfigLabel = this.translations.CnilActionTypes;
            this.fieldsConfig.push({
              label: fieldConfigLabel,
              name: 'cnil-action-types',
              type: 'multipleCheckbox',
              options: this.actionTypes,
              condition: null,
              validations: [],
              value: this.cnilIncludedActionTypes
            } as FieldConfigModel);
          }
        );
      }
    );
  }
}
