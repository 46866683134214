<mat-form-field [formGroup]="formGroup">
  <label for="{{fieldConfig.name}}">{{fieldConfig.label}} <span *ngIf="fieldConfig.validations.length" class="required-field">*</span></label>
  <input  matInput
          [formControlName]="fieldConfig.name"
          [matAutocomplete]="autoCompleteField">
  <mat-autocomplete #autoCompleteField="matAutocomplete" [displayWith]="displayFn.bind(this)">
      <mat-option *ngFor="let item of filtredOptions | async" [(value)]="(item.key == null) ? item : item.key">
        {{(item.value == null) ? item : item.value}}
      </mat-option>
  </mat-autocomplete>
  <ng-container *ngFor="let validation of fieldConfig.validations;" ngProjectAs="mat-error">
    <mat-error *ngIf="formGroup.get(fieldConfig.name)?.hasError(validation.name)">{{validation.message}}</mat-error>
  </ng-container>
</mat-form-field>
