import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IndividualModel } from 'common-services';

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html'
})
export class EditUserComponent {
  @Input() userToEdit: IndividualModel;
  @Output() updateUser: EventEmitter<any> = new EventEmitter();
}
