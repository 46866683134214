<form class="form" [formGroup]="dynamicFieldForm" (ngSubmit)="save()" #dynamicFieldFormTag="ngForm">
    <div class="row">
        <mat-form-field appearance="outline" class="hidden-field">
            <input [formControl]="dynamicFieldForm.controls.id" matInput>
        </mat-form-field>
        <!-- Field Name -->
        <mat-form-field class="col-lg-4 col-md-4 col-sm-6 col-xs-6">
            <label for="label">
                {{'fieldName' | translate }} <span class="required-field">*</span>
            </label> 
            <input type="text" [formControl]="dynamicFieldForm.controls.label" matInput required>
            <mat-error *ngIf="dynamicFieldForm.controls.label.hasError('required') && dynamicFieldForm.controls.label.touched">
                {{'requiredField' | translate }}
            </mat-error>
        </mat-form-field>

        <!-- Type Value -->
        <mat-form-field class="col-lg-4 col-md-4 col-sm-6 col-xs-6">
            <label for="valueType">{{'valueType' | translate }} <span class="required-field">*</span></label>
            <mat-select [formControl]="dynamicFieldForm.controls.type">
                <mat-option *ngFor="let valueType of valueTypes" [value]="valueType">
                    {{ getValueType(valueType) }}
                </mat-option>
            </mat-select>
            <mat-error *ngIf="dynamicFieldForm.controls.type.hasError('required') && dynamicFieldForm.controls.type.touched">
                {{'requiredField' | translate }}
            </mat-error>
        </mat-form-field>

        <!-- Field Type -->
        <mat-form-field class="col-lg-4 col-md-4 col-sm-6 col-xs-6">
            <label for="fieldType">{{'fieldType' | translate }} <span class="required-field">*</span></label>
            <mat-select 
            [formControl]="dynamicFieldForm.controls.inputControlType" 
            (selectionChange)="onFieldTypeChange($event.value)">
                <mat-option *ngFor="let inputControlType of inputControlTypes" [value]="inputControlType">
                    {{ getInputControlType(inputControlType) }}
                </mat-option>
            </mat-select>
            <mat-error *ngIf="dynamicFieldForm.controls.inputControlType.hasError('required') && dynamicFieldForm.controls.inputControlType.touched">
                {{'requiredField' | translate }}
            </mat-error>
        </mat-form-field>

        <!-- Parent Field -->
        <mat-form-field class="col-lg-4 col-md-4 col-sm-6 col-xs-6">
            <label for="parentField">{{ 'parentField' | translate  }}</label>
            <input  matInput
                    [formControl]="dynamicFieldForm.controls.parentField"
                    [matAutocomplete]="autoCompleteParentField">
            <mat-autocomplete #autoCompleteParentField="matAutocomplete" [displayWith]="displayParentFieldLabel.bind(this)">
                <mat-option *ngFor="let parentField of filteredParentFields | async" [value]="parentField">
                {{parentField.label}}
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>

        <!-- Sheets -->
        <mat-form-field class="col-lg-4 col-md-4 col-sm-6 col-xs-6">
            <label for="entities">{{ 'useInForms' | translate }}</label>
            <mat-select [formControl]="dynamicFieldForm.controls.entities" color="primary" multiple>
                <mat-option *ngFor="let config of dynamicFieldEntities" [value]="config">{{ getConfigEntity(config) }}</mat-option>
            </mat-select>
        </mat-form-field>

        <!-- Multiple Values -->
        <mat-form-field *ngIf="isSelectControlType" class="col-lg-4 col-md-4 col-sm-6 col-xs-6 multiple-values">
            <label for="multipleValues">{{'multipleValues' | translate }}</label>
            <mat-checkbox [formControl]="dynamicFieldForm.controls.multipleValues" color="primary" [ngModel]="dynamicField?.multipleValues"></mat-checkbox>
            <input matInput hidden>
        </mat-form-field>
    </div>
    <!-- Button Save -->
    <div class="action-btn">
        <button mat-raised-button class="btn-eolia btn-primary" [disabled]="!dynamicFieldForm.dirty || isSaving">
            <mat-icon>check</mat-icon>
            {{ 'save' | translate }}
        </button>
    </div>
</form>

<!-- Predefined Values -->
<app-dynamic-fields-predefined-values *ngIf="isSelectControlType" [dynamicField]="currentDynamiqueField"></app-dynamic-fields-predefined-values>
