import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationsService } from 'common-services';
import { Subscription } from 'rxjs';
import { TranslationHelper } from 'src/app/shared/helpers/translation.helper';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {

  public readonly loginForm: FormGroup;
  public error: string;

  private translations: Record<string, string>;
  private readonly subscription = new Subscription();;

  constructor(
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly formBuilder: FormBuilder,
    private readonly authenticationsService: AuthenticationsService,
    private readonly translateService: TranslateService,
    private readonly translationHelper: TranslationHelper
  ) {
    // Création du formulaire d'authentification
    this.loginForm = this.formBuilder.group({
      login: new FormControl('', [Validators.required]),
      password: new FormControl('', [Validators.required])
    });
  }

  ngOnInit(): void {
    // on utilise la langue par défaut du navigateur
    this.translationHelper.initTranslation(navigator.language);
    const translateSubscription = this.translateService.get(['invalidCredentials', 'error'])
      .subscribe(translation => {
        this.translations = translation;
      });
    this.subscription.add(translateSubscription);
    // Vérifier l'authentification par token
    this.activatedRoute.queryParams.subscribe(params => {
      let token = params?.token;
      if (token) {
        token = token.replace(/\s/g, '+');
        this.authenticationsService.authenticateByToken(token).then((result) => {
          if (result) {
            this.translationHelper.initTranslation(JSON.parse(localStorage.getItem('individual'))?.languageBU?.code);
            this.router.navigate(['/']);
          }
        });
      }
    });

    // Redirection vers l'accueil si l'utilisateur est connecté
    if (this.authenticationsService.isLoggedIn()) {
      this.translationHelper.initTranslation(JSON.parse(localStorage.getItem('individual'))?.languageBU?.code);
      this.router.navigate(['/']);
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public onSubmit(): void {
    const login = this.loginForm.controls.login.value.trim();
    const password = this.loginForm.controls.password.value.trim();
    this.authenticationsService.login(login, password).then(logged => {
      if (logged) {
        this.router.navigate(['/']);
        this.translationHelper.initTranslation(JSON.parse(localStorage.getItem('individual'))?.languageBU?.code);
      }
    }).catch((error) => {
      if (error.status === 401) {
        this.error = this.translations.invalidCredentials;
      } else {
        this.error = this.translations.error;
      }
    });
  }
}
