import { Component, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';
import { DynamicFieldDefaultValueModel, DynamicFieldModel, DynamicFieldPredefinedValueModel, DynamicFieldService } from 'common-services';
import { Subscription } from 'rxjs';
import { ConfirmDialogComponent } from 'src/app/generic-components/confirm-dialog/confirm-dialog.component';
import { FormDialogComponent } from 'src/app/generic-components/form-dialog/form-dialog.component';
import { Dialogs } from 'src/app/shared/enums/dialogs-enum';
import { TranslatableDomainModelName } from 'src/app/shared/enums/translatable-domain-model-name.enum';
import { UtilHelper } from 'src/app/shared/helpers/util.helper';
import { DialogsManager } from 'src/app/shared/managers/dialog.manager';
import { FieldConfigModel } from 'src/app/shared/models/field.model';
import { pageSize } from 'src/globals';

@Component({
    selector: 'app-dynamic-fields-predefined-values',
    templateUrl: './dynamic-fields-predefined-values.component.html',
    styleUrls: ['./dynamic-fields-predefined-values.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class DynamicFieldPredefinedValuesComponent implements OnInit, OnDestroy {
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @Input() dynamicField: DynamicFieldModel;

    public readonly displayedColumns = ['label', 'order', 'default'];
    public translatableDomainModelName: string;
    public pageSize = pageSize;
    public fields: DynamicFieldPredefinedValueModel[];
    public title: string;

    private readonly subscription: Subscription;
    private fieldsConfig: FieldConfigModel[];
    private formFields: string[];
    private translations: Record<string, string>;

    constructor(
        private readonly dialogManager: DialogsManager,
        private readonly dynamicFieldService: DynamicFieldService,
        private readonly translateService: TranslateService,
        private readonly utilHelper: UtilHelper
    ) {
        this.formFields = ['label', 'order', 'default'];
        this.subscription = new Subscription();
        this.translatableDomainModelName = TranslatableDomainModelName.predefinedValues;
    }

    public ngOnInit() {

        const translateSubscription = this.translateService.get(
            this.displayedColumns.concat(
                [
                    'new',
                    'update',
                    'deleteConfirmationTitle',
                    'deleteConfirmationMessage',
                    'delete'
                ]
            )
        ).subscribe(translation => {
            this.translations = translation;
        });

        this.subscription.add(translateSubscription);

        this.subscription.add(this.translateService.get('predefinedValues').subscribe(trad => this.title = trad));

        this.fields = this.dynamicField ? this.dynamicField.predefinedValues : [];
    }

    public ngOnDestroy(): void {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    public isDefault(predefinedValue: DynamicFieldPredefinedValueModel): boolean {
        return this.dynamicField.defaultValues.find(dv => dv.predefinedValueId === predefinedValue.id) ? true : false;
    }

    public onCreate() {
        this.buildFormFields();
        this.addPredefinedValue();
    }

    public onUpdate(predefinedValueToUpdate: DynamicFieldPredefinedValueModel) {
        this.buildFormFields();

        // On remplit les champs
        if (predefinedValueToUpdate) {
            this.fieldsConfig.forEach(field => {
                Object.entries(predefinedValueToUpdate).forEach(([key, value]) => {
                    if (field.name === 'default') {
                        field.value = this.isDefault(predefinedValueToUpdate) ? true : undefined;
                    } else if (field.name === key) {
                        field.value = value;
                    }
                });
            });
        }

        this.updatePredefinedValue(predefinedValueToUpdate);
    }

    public onDelete(predefinedValue: DynamicFieldPredefinedValueModel) {
        // Confirm dialog
        const data = {
            title: this.translations.deleteConfirmationTitle,
            message: this.translations.deleteConfirmationMessage,
            confirmButtonText: this.translations.delete
        };

        const confirmDialogComponent = this.dialogManager.openDialog(ConfirmDialogComponent, Dialogs.confirmDialog, data);
        confirmDialogComponent.afterClosed().subscribe(result => {
            if (result) {
                this.dynamicFieldService.deleteDynamicFieldPredefinedValue(this.dynamicField.id, predefinedValue.id).then(() => {
                    this.getPredefinedValues();
                    this.utilHelper.showNotification('deleteWithSuccess');
                }).catch(() => {
                    this.utilHelper.showNotification('thisValueIsSetAsTheDefault', false);
                });
            }
        });
    }

    private getPredefinedValues() {
        this.dynamicFieldService.getPredefinedValues(this.dynamicField.id).then((predefinedValues) => {
            this.fields = predefinedValues;
        });
    }

    // Ajoute une nouvelle valeur Prédéfinie
    private addPredefinedValue() {
        const data = {
            title: this.translations.new,
            fieldsConfig: this.fieldsConfig
        };

        const formDialogComponent = this.dialogManager.openDialog(FormDialogComponent, Dialogs.formDialog, data).componentInstance;
        formDialogComponent.save.subscribe((predefinedValue: any) => {
            // On crée la nouvelle valeur prédéfinie
            const predefinedValueModel = {
                label: predefinedValue.label,
                value: predefinedValue.label,
                order: predefinedValue.order,
                fieldId: this.dynamicField.id
            } as DynamicFieldPredefinedValueModel;

            this.dynamicFieldService.addDynamicFieldPredefinedValue(predefinedValueModel, this.dynamicField.id)
                .then((addedPredefinedValue) => {
                    // Ajouter la valeur par défaut si la case est cocher
                    if (predefinedValue.default) {
                        const defaultValueModel = {
                            fieldId: this.dynamicField.id,
                            predefinedValueId: addedPredefinedValue.id,
                            label: addedPredefinedValue.label
                        } as DynamicFieldDefaultValueModel;
                        this.dynamicFieldService.addDynamicFieldDefaultValue(defaultValueModel, this.dynamicField.id);
                    }
                    this.getPredefinedValues();
                    formDialogComponent.dialogRef.close();
                    this.utilHelper.showNotification('addSuccess');
                }).catch(() => {
                    this.utilHelper.showNotification('error', false);
                });
        });
    }

    // Modifier une valeur prédéfinie
    private updatePredefinedValue(predefinedValueToUpdated: DynamicFieldPredefinedValueModel) {
        const data = {
            title: `${this.translations.update} (${predefinedValueToUpdated.label})`,
            fieldsConfig: this.fieldsConfig
        };

        const formDialogComponent = this.dialogManager.openDialog(FormDialogComponent, Dialogs.formDialog, data).componentInstance;
        formDialogComponent.save.subscribe((predefinedValue: any) => {
            // On crée la valeur prédifinie
            const predefinedValueModel = {
                label: predefinedValue.label,
                value: predefinedValue.label,
                order: predefinedValue.order,
                fieldId: this.dynamicField.id
            } as DynamicFieldPredefinedValueModel;

            this.dynamicFieldService.updateDynamicFieldPredefinedValue(
                this.dynamicField.id,
                predefinedValueToUpdated.id,
                predefinedValueModel
            ).then((updatedPredefinedValue) => {
                // On vérifie si la valeur prédéfinie est utilisé comme valeur par défaut
                if (!predefinedValue.default && this.isDefault(updatedPredefinedValue)) {
                    const defaultValue = this.dynamicField.defaultValues.find(dv => dv.predefinedValueId === updatedPredefinedValue.id);
                    this.dynamicFieldService.deleteDynamicFieldDefaultValue(this.dynamicField.id, defaultValue.id);
                } else if (predefinedValue.default && !this.isDefault(updatedPredefinedValue)) {
                    const defaultValueModel = {
                        fieldId: this.dynamicField.id,
                        predefinedValueId: updatedPredefinedValue.id,
                        label: updatedPredefinedValue.label
                    } as DynamicFieldDefaultValueModel;
                    this.dynamicFieldService.addDynamicFieldDefaultValue(defaultValueModel, this.dynamicField.id);
                }
                this.getPredefinedValues();
                formDialogComponent.dialogRef.close();
                this.utilHelper.showNotification('updateSuccess');
            }).catch(() => {
                this.utilHelper.showNotification('error', false);
            });
        });
    }

    private buildFormFields() {
        // Initialiser les champs du formulaire
        this.fieldsConfig = [];

        this.formFields.forEach(field => {
            const validations = [];
            let inputType = 'text';
            let type = 'input';

            switch (field) {
                case ('label'):
                    validations.push(this.utilHelper.getValidator('required'));
                    break;
                case ('order'):
                    inputType = 'number';
                    validations.push(this.utilHelper.getValidator('min'));
                    break;
                case ('default'):
                    type = 'switcher';
                    inputType = '';
                    break;
                default:
                    break;
            }

            this.fieldsConfig.push({
                type,
                inputType,
                label: this.translations[field],
                name: field,
                validations,
                condition: null
            });
        });
    }
}
