import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FieldConfigModel } from 'src/app/shared/models/field.model';

@Component({
  selector: 'app-multiple-select',
  templateUrl: './multiple-select.component.html'
})
export class MultipleSelectComponent implements OnInit {
  public fieldConfig: FieldConfigModel;
  public formGroup: FormGroup;

  private selectValue: Record<string, unknown> = {};

  ngOnInit(): void {
    this.selectValue[this.fieldConfig.name] = this.fieldConfig.selected;
    this.formGroup.patchValue(this.selectValue);
  }

}
