<div id="login">
  <div class="form-wrapper">
    <form [formGroup]="loginForm">

      <div *ngIf="error" class="alert alert-danger">
        {{ error }}
      </div>

      <mat-form-field class="example-full-width">
        <label for="login">
          {{ 'userName' | translate }}
        </label>
        <div>
          <input type="text" matInput formControlName="login">
        </div>
      </mat-form-field>

      <mat-form-field class="example-full-width">
        <label for="password">
          {{ 'password' | translate }}
        </label>
        <div>
          <input type="password" matInput formControlName="password">
        </div>
      </mat-form-field>

      <div class="input-wrapper">
        <button class="btn-eolia btn-primary" (click)="onSubmit()" mat-raised-button>{{ 'login' | translate }}</button>
      </div>
    </form>
  </div>
</div>
