import { KeyValue } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { distinctUntilChanged, map, startWith } from 'rxjs/operators';
import { FieldConfigModel } from 'src/app/shared/models/field.model';

@Component({
  selector: 'app-autocomplete',
  templateUrl: './autocomplete.component.html'
})
export class AutoCompleteComponent implements OnInit {
  public fieldConfig: FieldConfigModel;
  public formGroup: FormGroup;
  public filtredOptions: Observable<Array<KeyValue<string | number, string>>>;

  ngOnInit(): void {
    this.filtredOptions = this.formGroup.get(this.fieldConfig.name).valueChanges
      .pipe(distinctUntilChanged())
      .pipe(
        startWith(''),
        map(result => result ? this.filterOptions(result) : this.fieldConfig.options.slice())
      );
  }

  public filterOptions(filter: string) {
    if (!filter || typeof filter !== 'string') {
      return this.fieldConfig.options.slice();
    }
    const filterValue = filter.toLowerCase();

    return this.fieldConfig.options.filter(op => op.value && op.value.toLowerCase().includes(filterValue)) ;
  }

  public displayFn(result: string | number) {
    if (result && typeof result === 'string') {
      return result.toString();
    }

    if (this.fieldConfig.options) {
      const option = this.fieldConfig.options.find(op => op.key === result);
      if (option) {
        return option.value;
      }
    }

    return undefined;
  }
}
