import { Component, EventEmitter, Input, Output } from '@angular/core';
import { EditableCardConfigModel } from 'src/app/shared/models/editableCardConfig.model';
import { FieldConfigModel } from 'src/app/shared/models/field.model';

@Component({
  selector: 'app-editable-card',
  templateUrl: './editable-card.component.html',
  styleUrls: ['./editable-card.component.scss']
})
export class EditableCardComponent<T> {
  @Input() editableCardConfig: EditableCardConfigModel;
  @Input() formFields: FieldConfigModel[];
  @Input() currentActiveForm = 0;
  @Output() edit: EventEmitter<number> = new EventEmitter<number>();
  @Output() update: EventEmitter<T> = new EventEmitter<T>();
  @Output() delete: EventEmitter<T> = new EventEmitter<T>();
  @Output() translate: EventEmitter<T> = new EventEmitter<T>();

  public onEdit(): void {
    this.edit.emit(this.editableCardConfig.item[this.editableCardConfig.identifier]);

    // Remplire les valeurs des champs
    this.formFields.forEach(field => {
      field.value = this.editableCardConfig.item[field.name];
    });
  }

  public onUpdate(item: T): void {
    this.update.emit(item);
  }

  public onDelete(): void {
    this.delete.emit(this.editableCardConfig.item);
  }

  public onTranslate(): void {
    this.translate.emit(this.editableCardConfig.item);
  }
}
