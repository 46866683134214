import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { TranslateService } from '@ngx-translate/core';
import { AgencyModel, DataDepthLevel, OrganizationService, QueryableModel, SortOrder } from 'common-services';
import { Subscription } from 'rxjs';
import { ConfirmDialogComponent } from 'src/app/generic-components/confirm-dialog/confirm-dialog.component';
import { Dialogs } from 'src/app/shared/enums/dialogs-enum';
import { UtilHelper } from 'src/app/shared/helpers/util.helper';
import { DialogsManager } from 'src/app/shared/managers/dialog.manager';
import { pageSize } from 'src/globals';

@Component({
  selector: 'app-agencies-list',
  templateUrl: './agencies-list.component.html'
})
export class AgenciesListComponent implements OnInit, OnDestroy {
  @ViewChild(MatPaginator) paginator: MatPaginator;

  public readonly displayedColumns = ['label', 'code', 'parentAgency'];
  public readonly sortByColumns = ['label', 'code', 'parentAgency'];
  public isList = true;
  public agency: AgencyModel;
  public agencies: Array<Record<string, string>>;
  public title: string;
  public rowCount: number;
  public currentPage = 0;
  public search = '';
  public sortColumn: Sort;

  private readonly subscription: Subscription;
  private translations: Record<string, string>;
  private sortOrder = SortOrder.Ascending.toString();;
  private sortExpression = 'label';
  private agenciesData: AgencyModel[];

  constructor(
    private readonly dialogManager: DialogsManager,
    private readonly translateService: TranslateService,
    private readonly utilHelper: UtilHelper,
    private readonly organisationService: OrganizationService
  ) {
    this.subscription = new Subscription();
  }

  ngOnInit(): void {
    const translateSubscription = this.translateService.get(
      [
        'deleteConfirmationTitle',
        'deleteAgencyConfirmationMessage',
        'delete',
        'agencies'
      ]
    ).subscribe(translation => {
      this.translations = translation;
      this.title = this.translations.agencies;
    });

    this.subscription.add(translateSubscription);

    // Récupérer les agences
    this.getAgencies();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public backToListing(): void {
    this.getAgencies();
    this.isList = true;
}

  // Crée une agence
  public onCreate(): void {
    this.agency = null;
    this.isList = false;
  }

  // Met à jour une agence
  public onEdit(agency: AgencyModel): void {
    this.isList = false;
    this.agency = this.agenciesData.find(a => a.id === agency.id);
  }

  // Supprime une agence
  public onDelete(agency: AgencyModel): void {
    // Confirm dialog
    const data = {
      title: this.translations.deleteConfirmationTitle,
      message: `${this.translations.deleteAgencyConfirmationMessage} ${agency.label}`,
      confirmButtonText: this.translations.delete
    };

    const confirmDialogComponent = this.dialogManager.openDialog(ConfirmDialogComponent, Dialogs.confirmDialog, data);
    confirmDialogComponent.afterClosed().subscribe(result => {
      if (result) {
        // Supprime l'agence après confirmation
        this.organisationService.deleteAgencyById(agency.id).then(() => {
          this.utilHelper.showNotification('deleteWithSuccess');
          this.getAgencies();
        }).catch(() => {
          this.utilHelper.showNotification('error', false);
        });
      }
    });
  }

  // Recherche
  public onSearch(search: string): void {
    this.search = search;
    this.currentPage = 0;
    this.getAgencies();
  }

  // Pagination des agences
  public onPaginate(page: number): void {
    this.currentPage = page - 1;
    this.getAgencies();
  }

  // Trier les agences
  public onSort(sort: Sort): void {
    this.sortColumn = sort;

    switch (sort.active) {
      case 'parentAgency':
        this.sortExpression = 'parent.label';
        this.changeSortOrder(sort.direction);
        break;

      default:
        this.sortExpression = sort.active;
        break;
    }

    // Modifier le tri des agences
    if (!sort.direction) {
      this.sortOrder = SortOrder.Ascending.toString();
      this.sortExpression = 'label';
    } else if (this.sortExpression === sort.active) {
      this.changeSortOrder(sort.direction);
    }

    // Récupérer les agences
    this.getAgencies();
  }

  // Changer l'ordre des utilisateur
  private changeSortOrder(direction = ''): void {
    this.sortOrder = (direction === 'desc') ? SortOrder.Descending.toString() : SortOrder.Ascending.toString();
  }

  private getAgencies() {

    let whereExpression = 'ag => true ';

    // Recherche par labele, agence mére ou code
    whereExpression += `AND ( `;
    whereExpression += `ag.label.contains("${this.search}") `;
    whereExpression += `OR ag.parent.label.contains("${this.search}") `;
    whereExpression += `OR ag.code.contains("${this.search}") `;
    whereExpression += `) `;

    const query: QueryableModel = {
      page: this.currentPage,
      whereExpression,
      dataDepthLevel: DataDepthLevel.WithSubObjectsAndSubLists,
      sortExpression: this.sortExpression,
      sortOrder: this.sortOrder,
      pageSize
    };

    this.organisationService.findAgenciesByQueryable(query)
      .then(result => {

        this.rowCount = result.rowCount;
        this.agencies = [];
        if (result?.items.length) {
          this.agenciesData = result.items;
          result.items.forEach(element => {
            // On cast l'objet a any pour changer la propriété parent du type AgencyModel à un string
            const agency = element as any;
            agency.parentAgency = agency.parent?.label ?? '';
            agency.undeleted = (agency.positions.length > 0) || (agency.individualAgencies.length > 0);
            this.agencies.push(agency);
          });
        }
      })
      .catch(() => {
        this.utilHelper.showNotification('error', false);
      });
  }
}
