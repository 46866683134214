import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import {
  CountryModel,
  DataDepthLevel,
  GeographicAreasService,
  LanguageModel,
  LanguageService, UserModel,
  UserService
} from 'common-services';
import { UtilHelper } from 'src/app/shared/helpers/util.helper';

@Component({
  selector: 'app-edit-user-information',
  templateUrl: './edit-user-information.component.html',
  styleUrls: ['./edit-user-information.component.scss']
})
export class EditUserInformationComponent implements OnInit {
  @Input() userGuid: string;

  public user: UserModel;
  public languages: Array<LanguageModel>;
  public countries: ReadonlyArray<CountryModel>;
  public userForm: FormGroup;

  constructor(
    private readonly userService: UserService,
    private readonly languageService: LanguageService,
    private readonly geographicAreasService: GeographicAreasService,
    private readonly utilHelper: UtilHelper,
    private readonly formBuilder: FormBuilder
  ) { }

  ngOnInit(): void {
    this.languageService.getApplicationLanguages()
      .then(result => this.languages = result);

    this.geographicAreasService.getAvailableCountries()
      .then(result => this.countries = result);

    this.userForm = this.formBuilder.group({
      login: new FormControl('', [Validators.required]),
      language: new FormControl('', [Validators.required]),
      countryCode: new FormControl('', [Validators.required]),
      active: new FormControl('', [Validators.required]),
      portalManagerAccess: new FormControl('', [Validators.required]),
      isTaskExecutor: new FormControl('', [Validators.required]),
      isSystem: new FormControl('', [Validators.required])
    });

    this.userService.getUserByGuid(this.userGuid, DataDepthLevel.Flat.toString())
      .then(user => {
        this.user = user;
        this.userForm.controls.login.setValue(user.login);
        this.userForm.controls.language.setValue(user.language);
        this.userForm.controls.countryCode.setValue(user.countryCode);
        this.userForm.controls.active.setValue(user.active);

        // Si l'utilisateur n'est pas actif, on désactive la modification des champs portalManagerAccess et isTaskExecutor
        if (!this.userForm.controls.active.value) {
          this.userForm.controls.portalManagerAccess.disable();
          this.userForm.controls.isTaskExecutor.disable();
        }

        this.userForm.controls.portalManagerAccess.setValue(user.portalManagerAccess);
        this.userForm.controls.isTaskExecutor.setValue(user.isTaskExecutor);
        this.userForm.controls.isSystem.setValue(user.isSystem);
      });
  }

  public onActiveChange() {
    // Si l'utilisateur est actif, on active la modification des champs portalManagerAccess et isTaskExecutorn sinon on les désactive
    if (this.userForm.controls.active.value) {
      this.userForm.controls.portalManagerAccess.enable();
      this.userForm.controls.isTaskExecutor.enable();
    } else {
      this.userForm.controls.portalManagerAccess.disable();
      this.userForm.controls.isTaskExecutor.disable();
    }
  }

  public editUser() {
    this.user.guid = this.userGuid;

    this.user.isSystem = this.userForm.value.isSystem;

    Object.keys(this.userForm.controls).forEach(key => {
      this.user[key] = this.userForm.controls[key].value;
    });

    this.userService.editUser(this.user.guid, this.user)
      .then(() => {
        this.utilHelper.showNotification('updateSuccess');
      })
      .catch(() => {
        this.utilHelper.showNotification('error', false);
      });
  }
}
