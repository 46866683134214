import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationsService, NavigatorHelper } from 'common-services';
import { TranslationHelper } from './shared/helpers/translation.helper';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit {
  public hideSidebar: boolean;
  public authByToken = false;

  constructor(
    private readonly router: Router,
    private readonly authenticationsService: AuthenticationsService,
    private readonly navigatorHelper: NavigatorHelper,
    private readonly translationHelper: TranslationHelper
  ) { }

  ngOnInit() {
    // Authentification par token
    const url = new URL(document.URL);
    const token = url.searchParams.get('token');
    this.authenticateByToken(token);

    // Afficher ou cacher la barre de navigation selon la taille d'écran
    this.hideSidebar = document.querySelector('body').clientWidth < 1200;
  }

  public onLogout(): void {
    this.authenticationsService.logout();
    this.router.navigate(['/login']);
  }

  public get isLoggedIn(): boolean {
    return this.authenticationsService.isLoggedIn();
  }

  public toggleSidebar() {
    this.hideSidebar = !this.hideSidebar;
  }

  // Authentification par token
  private authenticateByToken(token: string): void {
    if (token) {
      // Detruire l'ancien cookie et supprimer le localStorage
      const cookieName = this.navigatorHelper.getDefaultConfig().name;
      const cookiePath = this.navigatorHelper.getDefaultConfig().name;
      this.navigatorHelper.destroy(cookieName, cookiePath);
      localStorage.clear();
      this.authByToken = true;
      token = token.replace(/\s/g, '+');

      this.authenticationsService
        .authenticateByToken(token)
        .then(result => {
          if (result) {
            // Initiliser les tards
            this.translationHelper.initTranslation(JSON.parse(localStorage.getItem('individual'))?.languageBU?.code);
            this.router.navigate(['/']);
          }
        })
        .catch(() => {
          // Redirection vers la page d'authentification
          this.router.navigate(['/login']);
        })
        .finally(() => (this.authByToken = false));
    }
  }
}
