<mat-form-field  [formGroup]="formGroup">
  <label for="{{fieldConfig.name}}">
    {{fieldConfig.label}} <span *ngIf="fieldConfig.validations.length" class="required-field">*</span>
  </label>
  <div>
    <input matInput [ngxMatDatetimePicker]="picker" [placeholder]="fieldConfig.name" [formControlName]="fieldConfig.name">
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <ngx-mat-datetime-picker #picker >
    </ngx-mat-datetime-picker>
    <ng-container *ngFor="let validation of fieldConfig.validations;" ngProjectAs="mat-error">
      <mat-error *ngIf="formGroup.get(fieldConfig.name)?.hasError(validation.name)">{{validation.message}}</mat-error>
    </ng-container>
  </div>
</mat-form-field>
