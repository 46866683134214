import { Injectable } from '@angular/core';
import { MissingTranslationHandler, MissingTranslationHandlerParams } from '@ngx-translate/core';

@Injectable()
export class TranslationHandler implements MissingTranslationHandler {

  // Préfixer les traductions par * s'elles n'existent pas
  handle(params: MissingTranslationHandlerParams): string {
    return `*${params.key}`;
  }
}
