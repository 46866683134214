import { Injectable } from '@angular/core';
import { Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { CustomSnackBarService } from 'custom-snack-bar';
import { ValidatorModel } from '../models/validator.model';

@Injectable({
  providedIn: 'root'
})
export class UtilHelper {

  constructor(
    private readonly translateService: TranslateService,
    private readonly customSnackBarService: CustomSnackBarService,
  ) { }

  public incrementOrder(orders: number[]): number {
    for (let i = 1; i <= orders.length; i++) {
      if (!orders.includes(i)) {
        return i;
      }
    }

    return orders.pop() + 1;
  }

  public showNotification(message: string, isSuccess = true): void {
    this.translateService.get(message).subscribe(trad => {
      if (isSuccess) {
        this.customSnackBarService.showSuccess(trad);
      } else {
        this.customSnackBarService.showDanger(trad);
      }
    });
  }

  public getValidator(type: string): ValidatorModel {
    let validator = null;
    this.translateService.get(['requiredField', 'maxLength', 'minValue'], { maxLength: 2 }).subscribe(trad => {
      switch (type) {
        case 'required':
          validator = {
            name: 'required',
            validator: Validators.required,
            message: trad.requiredField
          };
          break;
        case 'maxlength':
          validator = {
            name: 'maxlength',
            validator: Validators.maxLength(2),
            message: trad.maxLength
          };
          break;
        case 'min':
          validator = {
            name: 'min',
            validator: Validators.min(1),
            message: trad.minValue
          };
          break;
      }
    });

    return validator;
  }
}
