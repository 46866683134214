import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { AgencyModel, DataDepthLevel, OrganizationService, QueryableModel, SortOrder } from 'common-services';
import { Subscription } from 'rxjs';
import { UtilHelper } from 'src/app/shared/helpers/util.helper';

@Component({
  selector: 'app-agency',
  templateUrl: './agency.component.html',
  styleUrls: ['./agency.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AgencyComponent implements OnInit, OnDestroy {
  @Input() agency?: AgencyModel;
  @Output() backToListing: EventEmitter<void> = new EventEmitter();

  public agencyForm: FormGroup;
  public agencies: ReadonlyArray<AgencyModel> = [];
  public currentAgency: AgencyModel;
  public maxLengthMessage = '';
  public agencyLabel = '';
  public isSaving = true;

  private maxLength = 2;
  private subscription: Subscription;

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly translateService: TranslateService,
    private readonly organizationService: OrganizationService,
    private readonly utilHelper: UtilHelper,
  ) {
    this.subscription = new Subscription();
    this.agencyForm = this.formBuilder.group({
      label: new FormControl('', [Validators.required]),
      code: new FormControl('', [Validators.required, Validators.maxLength(this.maxLength)]),
      parentId: new FormControl('', [Validators.required])
    });
  }

  ngOnInit(): void {
    this.getAgencies();

    if (this.agency) {
      this.currentAgency = this.agency;
      this.agencyForm.markAsTouched();
      this.agencyForm.controls.label.setValue(this.agency.label);
      this.agencyForm.controls.code.setValue(this.agency.code);
      this.agencyForm.controls.parentId.setValue(this.agency.parentId);
    }

    this.isSaving = false;

    const translateSubscription = this.translateService.get('maxLength', { maxLength: this.maxLength })
      .subscribe(translation => {
        this.maxLengthMessage = translation;
      });
    this.subscription.add(translateSubscription);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public addAgency() {
    const agency = Object.assign({}, this.agencyForm.getRawValue());
    agency.id = 0;
    this.organizationService.addAgency(agency).then(
      () => {
        this.utilHelper.showNotification('addSuccess');
        this.isSaving = false;
        this.backToListing.emit();
      }
    ).catch(() => {
      this.utilHelper.showNotification('error', false);
      this.isSaving = false;
    });
  }

  public editAgency() {
    const agencyToUpdate = Object.assign({}, this.agencyForm.getRawValue());
    this.organizationService.editAgency(agencyToUpdate).then(
      () => {
        this.utilHelper.showNotification('updateSuccess');
        this.isSaving = false;
        this.backToListing.emit();
      }
    ).catch(() => {
      this.utilHelper.showNotification('error', false);
      this.isSaving = false;
    });
  }

  public saveAgency() {
    if (this.agencyForm.dirty && this.agencyForm.valid) {
      this.isSaving = true;
      if (!this.agency) {
        this.addAgency();
      } else {
        this.editAgency();
      }
    } else {
      this.agencyForm.markAllAsTouched();
    }
  }

  private getAgencies() {
    // Éliminer les agences filles et l'agence  de la liste des agences si on est en mode modification
    const whereExpression = this.agency?.id ? `a => a.id != ${this.agency?.id} && a.parent.id != ${this.agency?.id}` : 'a => true';
    const query: QueryableModel = {
      whereExpression,
      dataDepthLevel: DataDepthLevel.WithSubObjects,
      sortExpression: 'label',
      sortOrder: SortOrder.Ascending.toString(),
      page: 0,
      pageSize: 1000
    };

    this.organizationService.findAgenciesByQueryable(query).then(agencies => {
      this.agencies = agencies?.items;
    });
  }
}
