import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import {
    DataDepthLevel,
    DynamicFieldEntity,
    DynamicFieldInputControlType,
    DynamicFieldModel,
    DynamicFieldService,
    DynamicFieldType,
    QueryableModel,
    SortOrder
} from 'common-services';
import { Observable } from 'rxjs';
import { distinctUntilChanged, map, startWith } from 'rxjs/operators';
import { UtilHelper } from 'src/app/shared/helpers/util.helper';

@Component({
    selector: 'app-dynamic-fields-form',
    templateUrl: './dynamic-fields-form.component.html',
    styleUrls: ['./dynamic-fields-form.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class DynamicFieldFormComponent implements OnInit {
    @Input() dynamicField?: DynamicFieldModel;

    @Output() backToListing: EventEmitter<void> = new EventEmitter();

    public currentDynamiqueField: DynamicFieldModel;
    public dynamicFieldForm: FormGroup;
    public dynamicFieldEntities: string[];
    public filteredParentFields: Observable<DynamicFieldModel[]>;
    public inputControlTypes: string[];
    public parentFields: DynamicFieldModel[];
    public valueTypes: string[];
    public isSaving = true;
    public isSelectControlType = false;

    constructor(
        private readonly formBuilder: FormBuilder,
        private readonly dynamicFieldService: DynamicFieldService,
        private readonly utilHelper: UtilHelper
    ) {
        this.dynamicFieldForm = this.formBuilder.group({
            id: new FormControl(),
            label: new FormControl('', { validators: [Validators.required] }),
            type: new FormControl('', { validators: [Validators.required] }),
            inputControlType: new FormControl('', { validators: [Validators.required] }),
            multipleValues: new FormControl(),
            parentField: new FormControl(),
            entities: new FormControl()
        });

        // Obtient les clés des énumerations
        this.valueTypes = Object.keys(DynamicFieldType).filter(f => !isNaN(Number(f)));
        this.inputControlTypes = Object.keys(DynamicFieldInputControlType).filter(f => !isNaN(Number(f)));
        this.dynamicFieldEntities = Object.keys(DynamicFieldEntity).filter(f => !isNaN(Number(f)));
    }

    public ngOnInit() {
        // Obtient la liste des champs dynamiques
        this.getDynamicFields();

        if (this.dynamicField) {
            this.currentDynamiqueField = this.dynamicField;
            this.dynamicFieldForm.markAsTouched();
            this.dynamicFieldForm.controls.id.setValue(this.dynamicField.id);
            this.dynamicFieldForm.controls.label.setValue(this.dynamicField.label);
            this.dynamicFieldForm.controls.type.setValue(this.valueTypes[this.dynamicField.type]);
            this.dynamicFieldForm.controls.inputControlType.setValue(this.inputControlTypes[this.dynamicField.inputControlType]);
            this.dynamicFieldForm.controls.multipleValues.setValue(this.dynamicField.multipleValues);
            this.dynamicFieldForm.controls.parentField.setValue(this.dynamicField.parentField);
            this.dynamicFieldForm.controls.entities.setValue(
                this.dynamicField.configurations.map(config => this.dynamicFieldEntities[config.entity])
            );

            if (DynamicFieldInputControlType.Select === this.dynamicField.inputControlType) {
                this.isSelectControlType = true;
            }

            this.dynamicFieldForm.controls.type.disable();
            this.dynamicFieldForm.controls.inputControlType.disable();
            this.dynamicFieldForm.controls.entities.disable();
        } else {
            this.dynamicFieldForm.reset();
            this.dynamicFieldForm.controls.id.setValue(0);
            this.dynamicFieldForm.patchValue({ multipleValues: false });
        }

        this.isSaving = false;
    }

    public onFieldTypeChange(type: number) {
        this.isSelectControlType = DynamicFieldInputControlType.Select.toString() === type.toString();
    }

    public getValueType(type: string) {
        return DynamicFieldType[type];
    }

    public getInputControlType(inputControlType: string) {
        return DynamicFieldInputControlType[inputControlType];
    }

    public getConfigEntity(entity: string) {
        return DynamicFieldEntity[entity];
    }

    public displayParentFieldLabel(parentField: DynamicFieldModel) {
        return parentField ? parentField.label : undefined;
    }

    public save() {
        if (this.dynamicFieldForm.dirty && this.dynamicFieldForm.valid) {
            this.isSaving = true;
            if (!this.dynamicField) {
                this.addDynamicField();
            } else {
                this.updateDynamicField();
            }
        } else {
            this.dynamicFieldForm.markAllAsTouched();
        }
    }

    private addDynamicField() {
        const entities = this.dynamicFieldForm.controls.entities.value;
        const dynamicField = Object.assign({}, this.dynamicFieldForm.getRawValue(), { entities: [] });
        dynamicField.id = 0;
        dynamicField.parentFieldId = dynamicField.parentField?.id;
        dynamicField.multipleValues = dynamicField.multipleValues ?? false;

        this.dynamicFieldService.addDynamicField(dynamicField).then((addedDynamicField) => {
            // Ajout des configurations liés au champ dynamique
            this.addConfigurationsOfDynamicField(entities, addedDynamicField);
            this.utilHelper.showNotification('addSuccess');
            // réinitialiser les champs
            if (addedDynamicField.inputControlType !== DynamicFieldInputControlType.Select) {
                this.dynamicFieldForm.reset();
                this.currentDynamiqueField = null;
            } else {
                this.currentDynamiqueField = addedDynamicField;
            }

            this.isSaving = false;

            this.backToListing.emit();
        }).catch(() => {
            this.utilHelper.showNotification('error', false);
            this.isSaving = false;
        });
    }

    private updateDynamicField() {
        const dynamicFieldToUpdate = Object.assign({}, this.dynamicFieldForm.getRawValue(), { entities: [] });
        dynamicFieldToUpdate.parentFieldId = dynamicFieldToUpdate.parentField?.id;

        this.dynamicFieldService.updateDynamicField(dynamicFieldToUpdate).then(() => {
            this.utilHelper.showNotification('updateSuccess');
            this.isSaving = false;
            this.dynamicFieldForm.markAsPristine();
            this.backToListing.emit();
        }).catch(() => {
            this.utilHelper.showNotification('error', false);
            this.isSaving = false;
        });
    }

    private addConfigurationsOfDynamicField(configurations: number[], dynamicField: DynamicFieldModel) {
        if (!configurations?.length) {
            return;
        }

        configurations.forEach(config => {
            this.dynamicFieldService.addDynamicFieldConfiguration(
                dynamicField.id,
                {
                    id: 0,
                    fieldId: dynamicField.id,
                    field: dynamicField,
                    entity: config,
                    required: false,
                    order: 0,
                    visible: true
                }
            );
        });
    }

    private filterDynamicFields(filter: string): DynamicFieldModel[] {
        const filterValue = filter ? filter.toLowerCase() : '';
        return this.parentFields.filter(pf => pf.label.toLowerCase().indexOf(filterValue) === 0);
    }

    private getDynamicFields() {
        const query: QueryableModel = {
            whereExpression: '',
            dataDepthLevel: DataDepthLevel.Flat,
            sortExpression: 'label',
            sortOrder: SortOrder.Ascending.toString(),
            page: 0,
            pageSize: 10000
        };

        this.dynamicFieldService.findByQueryable(query).then(data => {
            this.parentFields = this.dynamicField ? data.items.filter(pf => pf.id !== this.dynamicField.id) : data.items;
        }).then(() => {
            this.filteredParentFields = this.dynamicFieldForm.controls.parentField.valueChanges
                .pipe(distinctUntilChanged())
                .pipe(
                    startWith(''),
                    map(value => typeof value === 'string' ? value : value?.label),
                    map(df => df ? this.filterDynamicFields(df) : this.parentFields.slice())
                );
        });
    }
}
