<mat-form-field [formGroup]="formGroup">
  <label for="{{fieldConfig.name}}">
    {{fieldConfig.label}} <span *ngIf="fieldConfig.validations.length" class="required-field">*</span>
  </label>
  <mat-select [placeholder]="fieldConfig.label" [formControlName]="fieldConfig.name" multiple>
    <mat-option *ngFor="let item of fieldConfig.options" [value]="(item.key == null) ? item : item.key">{{(item.value == null) ? item : item.value}}</mat-option>
  </mat-select>
  <ng-container *ngFor="let validation of fieldConfig.validations;" ngProjectAs="mat-error">
    <mat-error *ngIf="formGroup.get(fieldConfig.name)?.hasError(validation.name)">{{validation.message}}</mat-error>
  </ng-container>
</mat-form-field>
