<div id="dynamic-fields-list">

    <div *ngIf="isList; else elseBlock;">
        <app-editable-table [sortColumn]="sortColumn" [pageIndex]="currentPage" [search]="search" [title]="title"
            [sortByColumns]="sortByColumns" [displayedColumns]="displayedColumns" [data]="fields" [hasTranslation]="true"
            [searchable]="true" [hasPagination]="true" [rowCount]="rowCount"
            [translatableDomainModelName]="translatableDomainModelName" (filter)="onSearch($event)"
            (paginate)="onPaginate($event)" (sort)="onSort($event)" (create)="onCreate()" (edit)="onEdit($event)"
            (delete)="onDelete($event)"></app-editable-table>
    </div>
    <ng-template #elseBlock>
        <h1 class=" setting-title">
            <mat-icon (click)="isList = true" class="material-icons-outlined">
                arrow_back
            </mat-icon>
            <span>{{ dynamicField ? dynamicField.label : ('new' | translate) }}</span>
        </h1>
        <app-dynamic-fields-form (backToListing)="backToListing()" [dynamicField]="dynamicField">
        </app-dynamic-fields-form>
    </ng-template>

</div>
