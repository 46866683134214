<div *ngIf="fieldConfig.inputType === 'hidden'; else elseblock;" [formGroup]="formGroup" class="hidden-input-wrapper">
  <input [formControlName]="fieldConfig.name" type="hidden">
</div>

<ng-template #elseblock>
  <mat-form-field [formGroup]="formGroup" appearance="none">
    <label for="{{fieldConfig.name}}">
      {{fieldConfig.label}} <span *ngIf="isRequired()" class="required-field">*</span>
    </label>
    <div>
      <input matInput [formControlName]="fieldConfig.name" [type]="fieldConfig.inputType" min="1">
      <div>
        <ng-container *ngFor="let validation of validations;" ngProjectAs="mat-error">
          <mat-error *ngIf="formGroup.get(fieldConfig.name)?.hasError(validation.name) && formGroup.get(fieldConfig.name).touched">
            {{validation.message}}
          </mat-error>
        </ng-container>
      </div>
    </div>
  </mat-form-field>
</ng-template>
