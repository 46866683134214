import { Injectable } from '@angular/core';
import { ApplicativeAreaCode, ApplicativeAreaModel, ApplicativeAreaParameterModel, HttpHelper } from 'common-services';

@Injectable()
export class ApplicativeAreaParameterService {
  private readonly applicativeAreasUrl = '/applicativeAreas';
  private readonly applicativeAreasParametersActionUrl = '/parameters';

  constructor(private readonly httpHelper: HttpHelper) { }

  public getApplicativeAreaParametersByApplicativeAreaCode(
    applicativeAreaCode: ApplicativeAreaCode
  ): Promise<ApplicativeAreaParameterModel> {
    return this.httpHelper.get<ApplicativeAreaParameterModel>(
      ApplicativeAreaParameterService.name,
      'getApplicativeAreaParametersByApplicativeAreaCode',
      `${this.applicativeAreasUrl}/${applicativeAreaCode}${this.applicativeAreasParametersActionUrl}`,
      ''
    );
  }

  public getApplicativeAreas(): Promise<Array<ApplicativeAreaModel>> {
    return this.httpHelper.get<Array<ApplicativeAreaModel>>(
      ApplicativeAreaParameterService.name,
      'getApplicativeAreas',
      this.applicativeAreasUrl,
      ''
    );
  }

  public updateApplicativeArea(
    applicativeAreaParameter: ApplicativeAreaParameterModel
  ): Promise<ApplicativeAreaParameterModel> {
    return this.httpHelper.put(
      ApplicativeAreaParameterService.name,
      'updateApplicativeArea',
      `${this.applicativeAreasUrl}/`,
      `${applicativeAreaParameter.applicativeAreaCode}${this.applicativeAreasParametersActionUrl}`,
      applicativeAreaParameter
    );
  }
}
