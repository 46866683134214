import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { TranslateService } from '@ngx-translate/core';
import {
    DataDepthLevel,
    DynamicFieldEntity,
    DynamicFieldInputControlType,
    DynamicFieldModel,
    DynamicFieldService,
    DynamicFieldType,
    QueryableModel,
    SortOrder
} from 'common-services';
import { Subscription } from 'rxjs';
import { ConfirmDialogComponent } from 'src/app/generic-components/confirm-dialog/confirm-dialog.component';
import { Dialogs } from 'src/app/shared/enums/dialogs-enum';
import { TranslatableDomainModelName } from 'src/app/shared/enums/translatable-domain-model-name.enum';
import { UtilHelper } from 'src/app/shared/helpers/util.helper';
import { DialogsManager } from 'src/app/shared/managers/dialog.manager';
import { pageSize } from 'src/globals';

@Component({
    selector: 'app-dynamic-fields-list',
    templateUrl: './dynamic-fields-list.component.html',
    encapsulation: ViewEncapsulation.None
})
export class DynamicFieldListComponent implements OnInit, OnDestroy {
    @Output() update: EventEmitter<DynamicFieldModel> = new EventEmitter();

    public readonly displayedColumns = ['fieldName', 'valueType', 'fieldType', 'parentField', 'multipleValues', 'entities'];
    public readonly sortByColumns = ['fieldName', 'valueType', 'fieldType', 'parentField', 'multipleValues'];
    public fields: Array<Record<string, string | number>>;
    public rowCount: number;
    public translatableDomainModelName: string;
    public title: string;
    public sortColumn: Sort;
    public isList = true;
    public dynamicField: DynamicFieldModel;
    public search = '';
    public currentPage = 0;

    private readonly subscription = new Subscription();;
    private translations: Record<string, string>;
    private sortExpression = 'id';
    private sortOrder = SortOrder.Descending.toString();
    private dynamicFields: DynamicFieldModel[];

    constructor(
        private readonly dialogManager: DialogsManager,
        private readonly translateService: TranslateService,
        private readonly utilHelper: UtilHelper,
        private readonly dynamicFieldService: DynamicFieldService
    ) {
        this.translatableDomainModelName = TranslatableDomainModelName.dynamicField;
    }

    public ngOnInit() {
        // Récupérer les champs dynamiques
        this.getDynamicFields();

        const translateSubscription = this.translateService.get(
            this.displayedColumns.concat(
                [
                    'new',
                    'update',
                    'deleteConfirmationTitle',
                    'deleteConfirmationMessage',
                    'delete',
                    'dynamicFields'
                ]
            )
        ).subscribe(translation => {
            this.translations = translation;
            this.title = translation.dynamicFields;
        });

        this.subscription.add(translateSubscription);
    }

    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public backToListing(): void {
        this.getDynamicFields();
        this.isList = true;
    }

    public onCreate(): void {
        this.dynamicField = null;
        this.isList = false;
    }

    // Met à jour un champ dynamique
    public onEdit(dynamicField: DynamicFieldModel): void {
        this.isList = false;
        this.dynamicField = this.dynamicFields.find(element => element.id === dynamicField.id);
    }

    // Supprime un champ dynamique
    public async onDelete(dynamicField: DynamicFieldModel) {
        // Confirm dialog
        const data = {
            title: this.translations.deleteConfirmationTitle,
            message: this.translations.deleteConfirmationMessage,
            confirmButtonText: this.translations.delete
        };

        const confirmDialogComponent = this.dialogManager.openDialog(ConfirmDialogComponent, Dialogs.confirmDialog, data);
        confirmDialogComponent.afterClosed().subscribe(async (result) => {
            if (result) {
                // On supprime les configuration liés au champ dynamique
                if (dynamicField.configurations.length > 0) {
                    await this.deleteConfigurationsOfDynamicField(dynamicField.configurations.map(config => config.id), dynamicField.id);
                }

                // On supprime le champ dynamique
                this.dynamicFieldService.deleteDynamicField(dynamicField.id).then(() => {
                    // On recalcul la liste des champs dynamiques
                    this.getDynamicFields();
                    this.utilHelper.showNotification('deleteWithSuccess');
                }).catch(() => {
                    this.utilHelper.showNotification('error', false);
                });
            }
        });
    }

    // Recherche
    public onSearch(search: string): void {
        this.search = search;
        this.currentPage = 0;
        this.getDynamicFields();
    }

    // Pagination
    public onPaginate(page: number): void {
        this.currentPage = page - 1;
        this.getDynamicFields();
    }

    // Trier les champs
    public onSort(sort: Sort): void {
        this.sortColumn = sort;

        switch (sort.active) {
            case 'fieldName':
                this.sortExpression = 'label';
                this.changeSortOrder(sort.direction);
                break;

            case 'valueType':
                this.sortExpression = 'type';
                this.changeSortOrder(sort.direction);
                break;

            case 'fieldType':
                this.sortExpression = 'inputControlType';
                this.changeSortOrder(sort.direction);
                break;

            case 'parentField':
                this.sortExpression = 'parentField.label';
                this.changeSortOrder(sort.direction);
                break;

            default:
                this.sortExpression = sort.active;
                break;
        }

        if (!sort.direction) {
            this.sortExpression = 'id';
            this.sortOrder = SortOrder.Descending.toString();
        } else if (this.sortExpression === sort.active) {
            this.changeSortOrder(sort.direction);
        }

        this.getDynamicFields();
    }

    // Récupérer les champs dynamiques
    private getDynamicFields(): void {
        const query: QueryableModel = {
            whereExpression: `field => field.label.contains("${this.search}") OR field.parentField.label.contains("${this.search}")`,
            dataDepthLevel: DataDepthLevel.WithSubObjectsAndSubLists,
            sortExpression: this.sortExpression,
            sortOrder: this.sortOrder,
            page: this.currentPage,
            pageSize
        };

        this.dynamicFieldService.findByQueryable(query).then(data => {
            this.fields = [];

            if (data?.items.length) {
                this.dynamicFields = data.items;
                // Cloner l'objet complet pour éviter la modification par référence
                JSON.parse(JSON.stringify(data.items)).forEach(item => {
                    const field = item as any;
                    field.fieldName = field.label;
                    field.valueType = this.getValueType(field.type);
                    field.fieldType = this.getFieldType(field.inputControlType);
                    field.parentField = field.parentField?.label;
                    field.multipleValues = field.multipleValues ? '✔' : 'X';
                    field.entities = this.getEntitiesOfDynamicField(field);
                    this.fields.push(field);
                });
            }

            this.rowCount = data?.rowCount;
        });
    }

    // Changer l'ordre de tri
    private changeSortOrder(direction = ''): void {
        this.sortOrder = (direction === 'desc') ? SortOrder.Descending.toString() : SortOrder.Ascending.toString();
    }

    private getValueType(type: number): string {
        return DynamicFieldType[type];
    }

    private getFieldType(field: number): string {
        return DynamicFieldInputControlType[field];
    }

    private getEntitiesOfDynamicField(dynamicField: DynamicFieldModel): string | string[] {
        const entities = dynamicField.configurations.map(config => DynamicFieldEntity[config.entity]);
        return entities.length > 0 ? entities.join(' / ') : entities;
    }
    private async deleteConfigurationsOfDynamicField(configurations: number[], dynamicFieldId: number) {
        for (const config of configurations) {
            await this.dynamicFieldService.deleteDynamicFieldConfiguration(
                dynamicFieldId,
                config
            );
        }
    }
}
