import { KeyValue } from '@angular/common';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FieldConfigModel } from 'src/app/shared/models/field.model';

@Component({
  selector: 'app-multiple-checkbox',
  templateUrl: './multiple-checkbox.component.html',
  styleUrls: ['./multiple-checkbox.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MultipleCheckboxComponent implements OnInit {
  public fieldConfig: FieldConfigModel;
  public formGroup: FormGroup;
  public options: Array<KeyValue<number | string, string>>;

  ngOnInit(): void {
    // Regrouper les options par ordre alphabetique
    this.options = this.fieldConfig.options.sort((arg1, arg2) => arg1.value.toLowerCase() > arg2.value.toLowerCase() ? 1 : -1);
  }

  public checked(key: number): boolean {
    return this.fieldConfig.value.includes(key);
  }

  public updateValue(key: number): void {
    if (this.checked(key)) {
      const index = this.fieldConfig.value.indexOf(key);
      this.fieldConfig.value.splice(index, 1);
    } else {
      this.fieldConfig.value.push(key);
    }
  }

  public classCss() {
    if( this.fieldConfig.options.length < 4 ) {
      return 'one-col';
    }else if( this.fieldConfig.options.length > 4 && this.fieldConfig.options.length < 9  ) {
      return 'two-col';
    }

    return 'three-col';
  }
}
