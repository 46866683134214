import { Component } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FieldConfigModel } from 'src/app/shared/models/field.model';

@Component({
  selector: 'app-date',
  templateUrl: './date.component.html'
})
export class DateComponent {
  public fieldConfig: FieldConfigModel;
  public formGroup: FormGroup;
}
