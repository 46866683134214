import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation
} from '@angular/core';
import {
  AsyncValidatorFn,
  FormBuilder,
  FormGroup,
  ValidatorFn,
  Validators
} from '@angular/forms';
import { ConditionedParameterOperator } from 'common-services';
import { FieldConfigModel } from 'src/app/shared/models/field.model';
import { FieldsetConfigModel } from 'src/app/shared/models/fielset-config.model';
import { ValidatorModel } from 'src/app/shared/models/validator.model';

@Component({
  selector: 'app-dynamic-form',
  templateUrl: './dynamic-form.component.html',
  styleUrls: ['./dynamic-form.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DynamicFormComponent implements OnInit, AfterViewChecked {
  @Input() fieldsConfig: FieldConfigModel[] = [];
  @Input() fieldsetConfig: FieldsetConfigModel[] = [];
  @Input() displayTitle = true;
  @Output() save: EventEmitter<any> = new EventEmitter<any>();

  public formGroup: FormGroup;
  public valid = true;

  constructor(
    private readonly formbuilder: FormBuilder,
    private readonly changeDetectorRef: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.formGroup = this.createControl();
  }

  ngAfterViewChecked(): void {
    this.changeDetectorRef.detectChanges();
  }

  // Regroupe les éléments dans un Fieldset
  public buildFieldSet(fieldConfig: FieldConfigModel): { position: string; title: string } {
    let position = '';
    let title = '';
    this.fieldsetConfig?.forEach((field) => {
      if (
        field.start === fieldConfig.label &&
        field.end === fieldConfig.label
      ) {
        position = 'one-field-group';
        title = field.title;
      } else if (field.start === fieldConfig.label) {
        position = 'start-group';
        title = field.title;
      } else if (field.end === fieldConfig.label) {
        position = 'end-group';
        title = field.title;
      }
    });

    return {
      position,
      title,
    };
  }

  public onSubmit(event: Event): void {
    event.preventDefault();
    event.stopPropagation();

    if (this.isValid()) {
      this.save.emit(this.formGroup.value);
    } else {
      this.formGroup.markAllAsTouched();
    }
  }

  public isValid(): boolean {
    // Vérifier est ce que les champs visibles dans le formulaire sont valides ou non
    let valid = true;

    this.fieldsConfig.forEach(field => {
      if (valid === false) {
        return;
      }

      if (this.displayField(field) && !this.formGroup.get(field.name).valid) {
        valid = false;
      }
    });

    return valid;
  }

  // Afficher le champ selon une condition
  public displayField(field: FieldConfigModel | null): boolean {
    const condition = field?.condition;
    if (!condition) {
      return true;
    }

    // Nom propriété parent
    const parentName = condition?.sourcePropertyName;
    // Valeur propriété parent
    const parentValue = String(this.formGroup.controls[parentName].value);

    return condition.conditionOperator === ConditionedParameterOperator.Equal ?
      condition.value === parentValue : condition.value !== parentValue;
  }

  // Création et ajout des formControls au formGroup
  private createControl(): FormGroup {
    const group = this.formbuilder.group({});
    this.fieldsConfig.forEach((field) => {
      if (field.type === 'button') {
        return;
      }
      group.addControl(
        field.name,
        this.formbuilder.control(
          field.value,
          this.bindValidations(field.validations || []),
          this.bindAsyncValidations(field.asyncValidations)
        )
      );
    });
    return group;
  }

  private bindValidations(validations: ValidatorModel[]): ValidatorFn | null {
    if (validations?.length <= 0) {
      return null;
    }

    const validatorsList = [];
    validations.forEach((v) => {
      validatorsList.push(v.validator);
    });

    return Validators.compose(validatorsList);
  }

  private bindAsyncValidations(validations: ValidatorModel): AsyncValidatorFn | null {
    if (!validations) {
      return null;
    }

    return validations.validator as AsyncValidatorFn;
  }
}
