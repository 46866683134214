import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { ApplicativeAreaCode, ApplicativeAreaModel, ApplicativeAreaParameterService, ConfigurationCode } from 'common-services';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SidebarComponent implements OnInit {
  public applicativeAreas: ApplicativeAreaModel[] = [];
  public activeItem: number;
  public activeSubItem: number;
  public activeConfigItem: number;

  private applicativeAreaChildren: ApplicativeAreaModel[][] = [];
  private currentApplicativeAreaCode: ApplicativeAreaCode;

  constructor(
    private readonly router: Router,
    private readonly applicativeAreaParameterService: ApplicativeAreaParameterService
  ) { }

  public ngOnInit(): void {
    this.applicativeAreaParameterService
      .getApplicativeAreas()
      .then((applicativeAreas: ApplicativeAreaModel[]) => {
        applicativeAreas.forEach((element) => {
          if (!element.parentCode) {
            this.applicativeAreas.push(element);
          } else {
            if (!this.applicativeAreaChildren[element.parentCode]) {
              this.applicativeAreaChildren[element.parentCode] = [];
            }
            this.applicativeAreaChildren[element.parentCode].push(element);
          }
        });
      });
  }

  // Trouver l'élément actif dans la navbar
  public isActiveLink(applicativeAreaCode: number): boolean {
    this.getParamFromUrl(this.router.url);
    const children = this.getApplicativeAreaChildren(applicativeAreaCode);

    return (
      this.currentApplicativeAreaCode === applicativeAreaCode ||
      !!children?.find(
        (element) => element.code === this.currentApplicativeAreaCode
      )
    );
  }

  public hasChildren(applicativeAreaCode: number): boolean {
    return this.getApplicativeAreaChildren(applicativeAreaCode).length > 0;
  }

  public getApplicativeAreaChildren(
    applicativeAreaCode: number
  ): ApplicativeAreaModel[] {
    return this.applicativeAreaChildren[applicativeAreaCode] ?? [];
  }

  public redirectToUrl(event: any, applicativeAreaCode: number): void {
    switch (applicativeAreaCode) {
      case ApplicativeAreaCode.DynamicFields:
        this.router.navigate(['dynamicFields']);
        break;
      case ApplicativeAreaCode.Users:
        this.router.navigate(['users']);
        break;
      default:
        this.router.navigate(['applicativeAreaParameters', applicativeAreaCode]);
        break;
    }

    this.activeSubItem = null;
    this.activeConfigItem = null;
    if (
      event.target === event.currentTarget ||
      event.toElement?.nodeName === 'MAT-ICON' ||
      event.target?.nodeName === 'MAT-ICON'
    ) {
      this.activeItem = (this.activeItem !== applicativeAreaCode) ? applicativeAreaCode : null;
    }
  }

  public clickSubLink(code: number): void {
    this.activeConfigItem = null;
    this.activeSubItem = this.activeSubItem !== code ? code : null;
  }

  // Redirection vers un component personnalisé
  public redirectToCustomRoute(event: any, code: number): void {
    this.activeConfigItem = code;
    switch (code) {
      case ConfigurationCode.CustomWebSearch:
        this.router.navigate(['customWebSearch']);
        break;
      case ConfigurationCode.CnilExcludedOrigin:
        this.router.navigate(['cnilExcludedOrigin']);
        break;
      case ConfigurationCode.CnilActionType:
        this.router.navigate(['cnilActionType']);
        break;
      case ConfigurationCode.Civility:
        this.router.navigate(['civility']);
        break;
      case ConfigurationCode.DynamicFieldForms:
        this.router.navigate(['dynamicFieldForms']);
        break;
      case ConfigurationCode.DynamicFieldForms:
        this.router.navigate(['dynamicFieldForms']);
        break;
      case ConfigurationCode.Agencies:
        this.router.navigate(['agencies']);
        break;
      default:
        break;
    }

    event.preventDefault();
    event.stopPropagation();
  }

  private getParamFromUrl(url: string) {
    const extractParams = url.split('/');
    extractParams.forEach(param => {
      if (param.length > 0) {
        if (isNaN(parseInt(param, 10))) {
          param = param.charAt(0).toUpperCase() + param.slice(1);
          const applicativeAreaCode = ApplicativeAreaCode[param];
          if (applicativeAreaCode) {
            this.currentApplicativeAreaCode = applicativeAreaCode;
          }
        } else {
          this.currentApplicativeAreaCode = parseInt(param, 10);
        }
      }
    });
  }
}
