import { NgxMatDatetimePickerModule, NgxMatNativeDateModule } from '@angular-material-components/datetime-picker';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule, MatOptionModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { BrowserModule } from '@angular/platform-browser';
import { TranslateModule } from '@ngx-translate/core';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { DynamicFieldComponent } from './dynamic-field/dynamic-field.component';
import { DynamicFormComponent } from './dynamic-form/dynamic-form.component';
import { EditableCardComponent } from './editable-card/editable-card.component';
import { EditableTableComponent } from './editable-table/editable-table.component';
import { EmptyDataComponent } from './empty-data/empty-data.component';
import { FormDialogComponent } from './form-dialog/form-dialog.component';
import { AutoCompleteComponent } from './form-fields/autocomplete/autocomplete.component';
import { DateComponent } from './form-fields/date/date.component';
import { InputComponent } from './form-fields/input/input.component';
import { MultipleCheckboxComponent } from './form-fields/multiple-checkbox/multiple-checkbox.component';
import { MultipleSelectComponent } from './form-fields/multiple-select/multiple-select.component';
import { SelectComponent } from './form-fields/select/select.component';
import { SwitcherComponent } from './form-fields/switcher/switcher.component';
import { TranslateComponent } from './translate/translate.component';

// Ajoutez vos components ici
const components = [
  AutoCompleteComponent,
  ConfirmDialogComponent,
  DateComponent,
  DynamicFieldComponent,
  DynamicFormComponent,
  EditableCardComponent,
  EditableTableComponent,
  EmptyDataComponent,
  FormDialogComponent,
  MultipleCheckboxComponent,
  InputComponent,
  SelectComponent,
  MultipleSelectComponent,
  SwitcherComponent,
  TranslateComponent
];

// Ajoutez vos modules ici
const modules = [
  BrowserModule,
  FormsModule,
  MatAutocompleteModule,
  MatButtonModule,
  MatIconModule,
  MatCardModule,
  MatFormFieldModule,
  MatInputModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatPaginatorModule,
  MatSelectModule,
  MatTableModule,
  MatOptionModule,
  MatCheckboxModule,
  MatMenuModule,
  MatSlideToggleModule,
  MatSortModule,
  NgxMatNativeDateModule,
  NgxMatDatetimePickerModule,
  ReactiveFormsModule,
  TranslateModule
];

@NgModule({
  declarations: [
    ...components
  ],
  imports: [
    ...modules
  ],
  providers: [
    { provide: MAT_DIALOG_DATA, useValue: {} },
    { provide: MatDialogRef, useValue: {} }
  ],
  exports: [
    ...modules,
    ...components
  ]
})
export class GenericComponentsModule { }
