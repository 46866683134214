import { Component } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FieldConfigModel } from 'src/app/shared/models/field.model';
import { ValidatorModel } from 'src/app/shared/models/validator.model';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html'
})
export class InputComponent {
  public fieldConfig: FieldConfigModel;
  public formGroup: FormGroup;

  public get validations(): ValidatorModel[] {
    return !this.fieldConfig?.asyncValidations ?
      this.fieldConfig.validations : [...this.fieldConfig.validations, this.fieldConfig?.asyncValidations];
  }

  public isRequired(): ValidatorModel {
    return this.fieldConfig.validations.find(validator => validator.name === 'required');
  }
}
