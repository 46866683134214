import { Injectable } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { Dialogs } from '../enums/dialogs-enum';

@Injectable({
  providedIn: 'root'
})
export class DialogHelper {
  private readonly formDialogConfig: MatDialogConfig;
  private readonly confirmDialogConfig: MatDialogConfig;
  private readonly translateDialogConfig: MatDialogConfig;

  constructor(
  ) {
    this.formDialogConfig = {
      height: 'auto',
      autoFocus: true,
      hasBackdrop: true,
      disableClose: true
    };
    this.translateDialogConfig = {
      height: 'auto',
      autoFocus: true,
      hasBackdrop: true,
      disableClose: true
    };
    this.confirmDialogConfig = {
      height: '200px',
      disableClose: true,
      hasBackdrop: true
    };
  }

  public getDialogConfig(dialog: Dialogs): MatDialogConfig {
    switch (dialog) {
      case Dialogs.formDialog:
        return this.formDialogConfig;
      case Dialogs.translateDialog:
        return this.translateDialogConfig;
      case Dialogs.confirmDialog:
        return this.confirmDialogConfig;
      default:
      return undefined;
    }
  }
}
