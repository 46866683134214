import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BrowserModule } from '@angular/platform-browser';
import { MissingTranslationHandler, TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { CommonServicesModule, IndividualModel } from 'common-services';
import { environment } from 'src/environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { GenericComponentsModule } from './generic-components/generic-components.module';
import { PagesModule } from './pages/pages.module';
import { TranslationHandler } from './shared/handlers/translation.handler';
import { SharedModule } from './shared/shared.module';

export const httpLoaderFactory = (http: HttpClient): TranslateHttpLoader => new TranslateHttpLoader(http);
const user: IndividualModel = JSON.parse(localStorage.getItem('individual'));

// Ajoutez vos modules ici
const modules = [
  AppRoutingModule,
  BrowserModule,
  CommonServicesModule.forRoot(environment),
  GenericComponentsModule,
  HttpClientModule,
  PagesModule,
  SharedModule,
  TranslateModule.forRoot({
    defaultLanguage: user?.languageBU?.code.split('-')[0] ?? 'fr',
    loader: {
      provide: TranslateLoader,
      useFactory: (http: HttpClient) => new TranslateHttpLoader(http),
      deps: [HttpClient]
    },
    missingTranslationHandler: {
      provide: MissingTranslationHandler,
      useClass: TranslationHandler
    },
  })
];

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    ...modules
  ],
  providers: [
    { provide: MAT_DIALOG_DATA, useValue: {} },
    { provide: MatDialogRef, useValue: {} }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
