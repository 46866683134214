import { KeyValue } from '@angular/common';
import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { AbstractControl, AsyncValidatorFn, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import {
  CivilityModel,
  CivilityService, CountryModel,
  DataDepthLevel,
  GeographicAreasService,
  LanguageModel,
  LanguageService,
  OrganizationService,
  UserService
} from 'common-services';
import { from, Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { UtilHelper } from 'src/app/shared/helpers/util.helper';
import { FieldConfigModel } from 'src/app/shared/models/field.model';
import { ValidatorModel } from 'src/app/shared/models/validator.model';

@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AddUserComponent implements OnInit, OnDestroy {
  @Output() createUser: EventEmitter<any> = new EventEmitter();

  public languages: Array<LanguageModel>;
  public civilities: Array<CivilityModel>;
  public userForm: FormGroup;
  public countries: ReadonlyArray<CountryModel>;
  public passMatch = true;
  public fieldsConfig: FieldConfigModel[];
  public displayForm = false;

  private subscription: Subscription;
  private languagesOptions: Array<KeyValue<number, string>> = [];
  private civilitiesOptions: Array<KeyValue<number, string>> = [];
  private countriesOptions: Array<KeyValue<string, string>> = [];

  constructor(
    private readonly userService: UserService,
    private readonly languageService: LanguageService,
    private readonly civilityService: CivilityService,
    private readonly geographicAreasService: GeographicAreasService,
    private readonly utilHelper: UtilHelper,
    private readonly organizationService: OrganizationService,
    private readonly translateService: TranslateService
  ) {
    this.subscription = new Subscription();
  }

  ngOnInit(): void {
    this.translateService.get([
      'userLogin', 'requiredField', 'language',
      'password', 'confirmPassword', 'civility',
      'firstName', 'lastName', 'trigram', 'function',
      'country', 'mailAddress', 'mobilePhone',
      'professionalPhone', 'invalidEmail',
      'passwordMatcH', 'emailAddressExists',
      'validPhoneNumber'
    ]).subscribe(translations => {

      Promise.all([
        this.languageService.getApplicationLanguages(),
        this.civilityService.getCivilities(),
        this.geographicAreasService.getAvailableCountries()
      ]).then(result => {
        this.languagesOptions = result[0].map(lang => ({ key: lang.id, value: lang.label }));
        this.civilitiesOptions = result[1].map(civility => ({ key: civility.id, value: civility.label }));
        this.countriesOptions = result[2].map(country => ({ key: country.code, value: country.label }));

        this.buildFormFieldsConfig(translations);

        this.displayForm = true;
      });
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  // Ajout d'un utilisateur
  public onSave(user: any) {
    user.tenantGuid = localStorage.getItem('tenantGuid');
    user.guid = null;

    delete user.confirmPassword;

    this.userService.addUser(user)
      .then(() => {
        this.utilHelper.showNotification('addSuccess');
        // Retourner vers la liste des utilisateurs
        this.createUser.emit();
      })
      .catch(() => {
        this.utilHelper.showNotification('error', false);
      });
  }

  // Vérifier si les mots de passe sont égaux
  private confirmPasswordValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const password = document.querySelector('#password-wrapper input') as HTMLInputElement;

      return password?.value !== control.value ? { notSame: true } : null;
    };
  }

  // Vérifier si l'Emil déjà utilisé par un autre utilisateur
  private takedEmailValidator(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      const query = {
        whereExpression: `p => p.mailAddress == "${control.value}"`,
        dataDepthLevel: DataDepthLevel.Flat,
        page: 0,
        pageSize: 1
      };

      return from(this.organizationService.findIndividualsByQueryable(query)).pipe(
        map(result => result.items.length > 0 ? { emailTaked: true } : null)
      );
    };
  }

  /**
   * Création de la configuration des champs de formulaire
   */
  private buildFormFieldsConfig(translations: Record<string, string>) {

    // Validation du formulaire
    const required: ValidatorModel = {
      name: 'required',
      validator: Validators.required,
      message: translations.requiredField
    };

    const emailValidator: ValidatorModel = {
      name: 'email',
      validator: Validators.email,
      message: translations.invalidEmail
    };

    const emailTakedValidator: ValidatorModel = {
      name: 'emailTaked',
      validator: this.takedEmailValidator(),
      message: translations.emailAddressExists
    };

    const passwordValidator: ValidatorModel = {
      name: 'notSame',
      validator: this.confirmPasswordValidator(),
      message: translations.passwordMatcH
    };

    const phoneValidator: ValidatorModel = {
      name: 'pattern',
      validator: Validators.pattern('^[0-9]{10}$'),
      message: translations.validPhoneNumber
    };

    // Champs du formulaire ajout d'un utilisateur
    this.fieldsConfig = [
      {
        label: translations.userLogin,
        name: 'login',
        type: 'input',
        validations: [required]
      },
      {
        label: translations.language,
        name: 'languageId',
        type: 'select',
        options: this.languagesOptions,
        validations: [required]
      },
      {
        label: translations.password,
        name: 'password',
        inputType: 'password',
        type: 'input',
        validations: [required]
      },
      {
        label: translations.confirmPassword,
        name: 'confirmPassword',
        inputType: 'password',
        type: 'input',
        validations: [required, passwordValidator]
      },
      {
        label: translations.civility,
        name: 'civilityId',
        type: 'select',
        options: this.civilitiesOptions,
        validations: [required]
      },
      {
        label: translations.firstName,
        name: 'firstName',
        type: 'input',
        validations: [required]
      },
      {
        label: translations.lastName,
        name: 'lastName',
        type: 'input',
        validations: [required]
      },
      {
        label: translations.function,
        name: 'function',
        type: 'input',
        validations: [required]
      },
      {
        label: translations.country,
        name: 'countryCode',
        type: 'select',
        options: this.countriesOptions,
        validations: [required]
      },
      {
        label: translations.mailAddress,
        name: 'mailAddress',
        type: 'input',
        validations: [required, emailValidator],
        asyncValidations: emailTakedValidator
      },
      {
        label: translations.mobilePhone,
        name: 'mobilePhone',
        type: 'input',
        validations: [required, phoneValidator]
      },
      {
        label: translations.professionalPhone,
        name: 'officePhone',
        type: 'input',
        validations: [required, phoneValidator]
      },
    ];
  }
}
