<div class="mb-5" id="add-user-form">

    <h1 class="setting-title">
        <mat-icon (click)="createUser.emit()">arrow_back</mat-icon>
        <span>
            {{ 'users' | translate}}
        </span>
    </h1>

    <app-dynamic-form *ngIf="displayForm" (save)="onSave($event)" [fieldsConfig]="fieldsConfig" [displayTitle]="false">
    </app-dynamic-form>

</div>
