<div class="editable-card">
  <div>
    <mat-icon class="drag">drag_indicator</mat-icon>
    <span *ngFor="let elm of editableCardConfig.title">
      {{editableCardConfig.item[elm]}} 
    </span>
    <span style="font-weight: bold;margin:10px 10px">|</span>
    <span *ngIf="editableCardConfig.titleDetails">
      {{ editableCardConfig.titleDetails.join(',') }}
    </span>
  </div>
  <div>
    <mat-icon *ngIf="editableCardConfig?.actions?.translate" (click)="onTranslate()" class="translate">translate</mat-icon>
    <mat-icon *ngIf="editableCardConfig?.actions?.edit" (click)="onEdit()" class="edit">edit</mat-icon>
    <mat-icon *ngIf="editableCardConfig?.actions?.delete" (click)="onDelete()" class="delete">delete</mat-icon>
  </div>
</div>
<div class="form" *ngIf="currentActiveForm === editableCardConfig.item[editableCardConfig.identifier]">
  <app-dynamic-form (save)="onUpdate($event)" [fieldsConfig]="formFields"></app-dynamic-form>
</div>
